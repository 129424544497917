<bfc-loading-overlay [loading]="showTableLoading" class="table-wrapper">
  <mat-dialog-content>
    <h1>{{ "DOCHUB_ADMIN.EDIT.EDIT_PERMISSIONS" | bfcTranslate }}</h1>
    <div class="bfc-form-row document-name" *ngIf="hasOneDocument(); else documentCount">
      {{ "DOCHUB_ADMIN.EDIT.DOCUMENT" | bfcTranslate }}: <b>{{ documentName }}</b>
    </div>
    <ng-template #documentCount>
      <div class="customer-name flex-column">
        <span class="bfc-margin-bottom">{{
          "DOCHUB_ADMIN.DOCUMENTS.LIST.COUNT_SELECTED_DOCUMENTS" | bfcTranslate: { number: selectedDocuments?.length }
        }}</span>
        <span>{{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.NOTE" | bfcTranslate }}</span>
      </div>
    </ng-template>

    <mat-error *ngIf="errorOnSubmit">{{
      "DOCHUB_ADMIN.EDIT.EDIT_PERMISSIONS_DOCUMENT_ERROR" | bfcTranslate
    }}</mat-error>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="entries-table">
        <ng-container matColumnDef="identity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.IDENTITY" | bfcTranslate }}
          </th>
          <td mat-cell *matCellDef="let permission" ngClass.gt-xs="nowrap">
            {{ permission?.identity }}
          </td>
        </ng-container>
        <ng-container matColumnDef="identityType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.IDENTITY_TYPE" | bfcTranslate }}
          </th>
          <td mat-cell *matCellDef="let permission" ngClass.gt-xs="nowrap">
            {{ permission?.identityType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="read">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.READ" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            [ngClass]="{ conflict: attributeValue(permission, permissionAttribute.READ) === '?' }"
            [ngClass.gt-xs]="gtXsClasses(permission, permissionAttribute.READ)"
          >
            {{ attributeValue(permission, permissionAttribute.READ) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="write">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.WRITE" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            [ngClass]="{ conflict: attributeValue(permission, permissionAttribute.WRITE) === '?' }"
            [ngClass.gt-xs]="gtXsClasses(permission, permissionAttribute.WRITE)"
          >
            {{ attributeValue(permission, permissionAttribute.WRITE) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="changePermission">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.CHANGE_PERMISSION" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            [ngClass]="{ conflict: attributeValue(permission, permissionAttribute.CHANGE_PERMISSION) === '?' }"
            [ngClass.gt-xs]="gtXsClasses(permission, permissionAttribute.CHANGE_PERMISSION)"
          >
            {{ attributeValue(permission, permissionAttribute.CHANGE_PERMISSION) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="inheritable">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.INHERITABLE" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            [ngClass]="{ conflict: attributeValue(permission, permissionAttribute.INHERITABLE) === '?' }"
            [ngClass.gt-xs]="gtXsClasses(permission, permissionAttribute.INHERITABLE)"
          >
            {{ attributeValue(permission, permissionAttribute.INHERITABLE) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isInherited">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.IS_INHERITED" | bfcTranslate }}
          </th>
          <td mat-cell *matCellDef="let permission" ngClass.gt-xs="nowrap">
            {{ permission?.isInherited }}
          </td>
        </ng-container>

        <ng-container matColumnDef="editPermission">
          <th mat-header-cell *matHeaderCellDef>
            {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.EDIT" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            ngClass.gt-xs="nowrap"
            matTooltip="{{ 'DOCHUB_ADMIN.EDIT.PERMISSIONS.EDIT' | bfcTranslate }}"
          >
            <a (click)="openAddOrEditPermissionDialog(permission)" [ngClass]="{ disabled: permission?.isInherited }">
              <bfe-icon name="bfc-icon-pencil" class="actions-icon"></bfe-icon>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            {{ "NET_USAGE.CONTRACTS.LIST.COLUMNS.DELETE" | bfcTranslate }}
          </th>
          <td
            mat-cell
            *matCellDef="let permission"
            ngClass.gt-xs="nowrap"
            matTooltip="{{ 'NET_USAGE.CONTRACTS.LIST.COLUMNS.DELETE' | bfcTranslate }}"
          >
            <a (click)="delete(permission)" [ngClass]="{ disabled: permission?.isInherited }">
              <bfe-icon name="bfc-icon-delete" class="actions-icon"></bfe-icon>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <button mat-button color="primary" (click)="openAddOrEditPermissionDialog()">
        <mat-icon svgIcon="plus" matTooltip="{{ 'DOCHUB_ADMIN.EDIT.ADD_PERMISSION' | bfcTranslate }}"></mat-icon>
      </button>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ "UPLOAD.CANCEL" | bfcTranslate }}
    </button>
    <button mat-button color="primary" class="bfc-button-hero" [disabled]="!selectedDocuments" (click)="submit()">
      {{ "UPLOAD.SAVE" | bfcTranslate }}
    </button>
  </mat-dialog-actions>
</bfc-loading-overlay>
