import { AfterViewInit, Component, HostBinding, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BfcTranslationService } from "@bfl/components/translation";
import { DocumentService } from "../../../core/services/document.service";
import { catchError, finalize, takeUntil } from "rxjs/operators";
import { forkJoin, of, Subject } from "rxjs";
import { PermissionDto } from "../../../generated/doc-hub/model/permissionDto";
import { DialogService } from "../../../core/services/dialog.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ErrorService } from "../../../core/services/error.service";
import { AddOrEditPermissionDialogComponent } from "../../add-or-edit-permission-dialog/add-or-edit-permission-dialog.component";
import { DocumentDto } from "../../../generated/doc-hub/model/documentDto";
import { PermissionAttributeEnum } from "../../../core/model/permission-attribute.enum";
import { DocumentPermissionService } from "../../../core/services/document-permission.service";
import { DocumentPatchOperation } from "../../../core/model/document-patch-operation";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-document-edit-permissions-dialog",
  templateUrl: "./document-edit-permissions-dialog.component.html",
  styleUrls: ["./document-edit-permissions-dialog.component.scss"],
})
export class DocumentEditPermissionsDialogComponent implements AfterViewInit, OnDestroy {
  @HostBinding("class")
  classes = "document-edit-permissions-dialog";

  @ViewChild(MatSort)
  private matSort: MatSort;

  public readonly selectedDocuments: DocumentDto[];

  private readonly callbackOnSuccess: () => void;

  private permissions: PermissionDto[];

  private allPermissions: PermissionDto[][];

  private permissionsToAdd: PermissionDto[] = [];

  private permissionsToRemove: PermissionDto[] = [];

  private unsubscribe: Subject<void> = new Subject();

  displayedColumns: string[] = [
    "identity",
    "identityType",
    "read",
    "write",
    "changePermission",
    "inheritable",
    "isInherited",
    "editPermission",
    "delete",
  ];

  dataSource: MatTableDataSource<PermissionDto>;

  showTableLoading = false;

  errorOnSubmit = false;

  permissionAttribute = PermissionAttributeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedDocuments: DocumentDto[];
      callBackOnSuccess: () => void;
    },
    private dialogRef: MatDialogRef<DocumentEditPermissionsDialogComponent>,
    private addOrEditPermissionDialog: MatDialog,
    private documentService: DocumentService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private translationService: BfcTranslationService,
    private documentPermissionService: DocumentPermissionService,
  ) {
    this.dataSource = new MatTableDataSource();

    this.selectedDocuments = data.selectedDocuments;
    this.callbackOnSuccess = data.callBackOnSuccess;

    this.loadPermissions();
  }

  submit() {
    this.createHttpRequest()
      .pipe(finalize(() => (this.showTableLoading = false)))
      .subscribe(
        () => {
          this.dialogRef.close();
          this.callbackOnSuccess();
        },
        () => {
          this.errorOnSubmit = true;
        },
      );
  }

  private createHttpRequest() {
    const updatePermissionsObs = [];
    let errorOnUpdatePermissionsOccurred = false;
    this.selectedDocuments.forEach((document: DocumentDto, index: number) => {
      const patchOperations: DocumentPatchOperation[] = this.documentPermissionService.getPatchOperations(
        this.allPermissions[index],
        this.permissionsToRemove,
        this.permissionsToAdd,
      );

      updatePermissionsObs.push(
        this.documentService.updateDocumentPermissions(document, patchOperations).pipe(
          catchError(() => {
            errorOnUpdatePermissionsOccurred = true;
            return of([]);
          }),
          takeUntil(this.unsubscribe),
        ),
      );
    });

    return forkJoin(updatePermissionsObs).pipe(
      finalize(() => {
        this.showTableLoading = false;
        if (errorOnUpdatePermissionsOccurred) {
          this.errorOnSubmit = true;
        }
      }),
    );
  }

  private loadPermissions() {
    this.showTableLoading = true;
    if (this.hasOneDocument()) {
      this.documentService
        .fetchDocumentPermissions(this.selectedDocuments[0])
        .pipe(
          finalize(() => (this.showTableLoading = false)),
          takeUntil(this.unsubscribe),
        )
        .subscribe(
          (permissions: PermissionDto[]) => {
            this.dataSource.data = this.permissions = permissions;
            this.allPermissions = [JSON.parse(JSON.stringify(this.permissions))];
          },
          (error: unknown) => {
            if ((error as HttpErrorResponse)?.status === 404) {
              this.dataSource.data = this.permissions = [];
            } else if ((error as HttpErrorResponse)?.status === 401) {
              this.errorService.handleError(error, this.translationService.translate("ERROR.LOAD_ERROR_UNAUTHORIZED"));
            } else {
              this.errorService.handleError(
                error,
                this.translationService.translate("DOCHUB_ADMIN.EDIT.ERROR.LOAD_ERROR_DOCUMENT_PERMISSIONS"),
              );
            }
          },
        );
    } else if (this.selectedDocuments?.length > 1) {
      const fetchPermissionObs = [];
      let errorOnFetchingDocumentsOccurred = false;
      this.selectedDocuments.forEach((selectedDocument: DocumentDto) => {
        fetchPermissionObs.push(
          this.documentService.fetchDocumentPermissions(selectedDocument).pipe(
            catchError(() => {
              errorOnFetchingDocumentsOccurred = true;
              return of([]);
            }),
            takeUntil(this.unsubscribe),
          ),
        );
      });
      forkJoin(fetchPermissionObs)
        .pipe(
          finalize(() => {
            this.showTableLoading = false;
            if (errorOnFetchingDocumentsOccurred) {
              this.errorService.showError(
                this.translationService.translate("DOCHUB_ADMIN.EDIT.ERROR.LOAD_ERROR_DOCUMENT_PERMISSIONS"),
              );
            }
          }),
        )
        .subscribe(
          (allPermissions: PermissionDto[][]) => {
            this.allPermissions = allPermissions;
            this.dataSource.data = this.permissions =
              this.documentPermissionService.getCommonPermissions(allPermissions);
          },
          (error: unknown) => {
            this.errorService.handleError(
              error,
              this.translationService.translate("DOCHUB_ADMIN.EDIT.ERROR.LOAD_ERROR_DOCUMENT_PERMISSIONS"),
            );
          },
        );
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.matSort;

    // set the dialog width for the table to fit
    const hostElements = document.getElementsByClassName("document-edit-permissions-dialog");
    let hostElement;

    if (hostElements.length > 0) {
      hostElement = hostElements[0];
    }

    if (!!hostElement) {
      hostElement.parentElement.style.width = "unset";
    }
  }

  delete(permission: PermissionDto) {
    this.dialogService
      .openConfirmationDialog(
        this.translationService.translate("DOCHUB_ADMIN.EDIT.PERMISSIONS.CONFIRMATION.TITLE"),
        this.translationService.translate("DOCHUB_ADMIN.EDIT.PERMISSIONS.CONFIRMATION.DESCRIPTION"),
      )
      .subscribe((submitted: boolean) => {
        if (submitted) {
          const index: number = this.permissions.indexOf(permission);
          if (index !== -1) {
            this.permissions.splice(index, 1);
            this.dataSource.data = this.permissions;

            // check if an edited permission has been deleted
            this.permissionsToAdd.forEach((permissionToAdd: PermissionDto, indexToSplice: number) => {
              if (this.documentPermissionService.permissionsEqual(permissionToAdd, permission)) {
                this.permissionsToAdd.splice(indexToSplice, 1);
              }
            });

            this.permissionsToRemove.push(permission);
          }
        }
      });
  }

  openAddOrEditPermissionDialog(permission?: PermissionDto) {
    const dialogRef = this.addOrEditPermissionDialog.open(AddOrEditPermissionDialogComponent, {
      data: {
        permission: permission,
      },
    });
    dialogRef.afterClosed().subscribe((newPermission: PermissionDto) => {
      if (!!newPermission) {
        if (!permission) {
          this.permissions.push(newPermission);
          this.permissionsToAdd.push(newPermission);
        } else if (!!permission) {
          const index = this.permissions.indexOf(permission);
          if (index !== -1) {
            this.permissions[index] = newPermission;
            this.permissionsToRemove.push(permission);
            this.permissionsToAdd.push(newPermission);
          }
        }
        this.dataSource.data = this.permissions;
      }
    });
  }

  get documentName(): string {
    return this.hasOneDocument() ? this.selectedDocuments[0].name : null;
  }

  attributeValue(permission: PermissionDto, permissionAttribute: PermissionAttributeEnum): boolean | string {
    return this.documentPermissionService.attributeValue(permission, permissionAttribute);
  }

  gtXsClasses(permission, permissionAttribute: PermissionAttributeEnum) {
    return {
      nowrap: true,
      conflict: this.attributeValue(permission, permissionAttribute) === "?",
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  hasOneDocument(): boolean {
    return this.selectedDocuments?.length === 1;
  }

  close() {
    this.dialogRef.close();
  }
}
