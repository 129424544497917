import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentService } from "../../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { throwError } from "rxjs";
import { MatInput } from "@angular/material/input";
import { MetaData } from "../../../core/model/meta-data";
import { DocumentDto } from "../../../generated/doc-hub/model/documentDto";
import { DocumentType } from "../../../generated/doc-hub/model/documentType";

@Component({
  selector: "app-document-edit-data-dialog",
  templateUrl: "./document-edit-data-dialog.component.html",
  styleUrls: ["./document-edit-data-dialog.component.scss"],
})
export class DocumentEditDataDialogComponent {
  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  public readonly selectedDocument: DocumentDto;

  private readonly callbackOnSuccess: () => void;

  formGroup: FormGroup;

  documentTypes: string[] = Object.keys(DocumentType);

  selectedDocumentType;

  showTableLoading = false;

  errorOnSubmit = false;

  metaDataFormGroup: FormGroup;

  metaDataEntries: [string, string][];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedDocument: DocumentDto;
      callBackOnSuccess: () => void;
    },
    private dialogRef: MatDialogRef<DocumentEditDataDialogComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
  ) {
    this.selectedDocument = data.selectedDocument;
    this.callbackOnSuccess = data.callBackOnSuccess;

    this.metaDataEntries = Object.entries(this.selectedDocument.metaData);

    this.populateMetaDataFormGroup();

    this.formGroup = formBuilder.group({
      name: [this.selectedDocument?.name, [Validators.required]],
      language: [this.selectedDocument?.language],
      type: [this.selectedDocument?.type, [Validators.required]],
      fileName: [this.selectedDocument?.filename, [Validators.required]],
      fileType: [this.selectedDocument?.fileType, [Validators.required]],
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;

      this.selectedDocument.name = this.formGroup.get("name").value as string;
      this.selectedDocument.language = this.formGroup.get("language").value as string;
      this.selectedDocument.type = this.formGroup.get("type").value as DocumentType;
      this.selectedDocument.filename = this.formGroup.get("fileName").value as string;
      this.selectedDocument.fileType = this.formGroup.get("fileType").value as string;

      const metaData: MetaData = {};

      Object.keys(this.metaDataFormGroup.controls).forEach((controlName) => {
        metaData[controlName] = this.metaDataFormGroup.get(controlName).value;
      });

      this.selectedDocument.metaData = metaData;

      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    if (!!this.selectedDocument) {
      const patchOperations = [
        { op: "add", path: "/name", value: this.formGroup.value.name },
        { op: "add", path: "/language", value: this.formGroup.value.language },
        { op: "add", path: "/type", value: this.formGroup.value.type },
        { op: "add", path: "/filename", value: this.formGroup.value.fileName },
        { op: "add", path: "/fileType", value: this.formGroup.value.fileType },
      ];

      Object.keys(this.selectedDocument.metaData).forEach((metaData) =>
        patchOperations.push({
          op: "add",
          path: `metaData/${metaData}`,
          value: this.metaDataFormGroup.get(metaData)?.value,
        }),
      );

      return this.documentService.updateDocument(this.selectedDocument, patchOperations);
    } else {
      return throwError("Not implemented!");
    }
  }

  private populateMetaDataFormGroup(): void {
    if (this.selectedDocument && this.metaDataEntries) {
      const metaData: MetaData = {};

      this.metaDataEntries.forEach((entry) => {
        metaData[entry[0]] = entry[1];
      });

      this.metaDataFormGroup = this.formBuilder.group(metaData);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
