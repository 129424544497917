import { IDocumentsAdminAppConfiguration } from "../configuration";
import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";
import { IS_IE } from "./general";

export const LOCAL_ENV: IDocumentsAdminAppConfiguration = {
  msalConfig: {
    auth: {
      clientId: "1c91658e-4c2e-46de-b094-953546311ddf", //  csi-dochub-api-test-admingui
      authority:
        "https://login.microsoftonline.com/bkwfmbenergietest.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE,
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [
      [
        "https://api-t.bkw.ch",
        ["https://csi-dochub-test.azurewebsites.net/user_impersonation"],
      ],
      // The best way is to register the own client (in this case op-admin-documents-backend) in azure. 
      // But if you only use pure jwt validation, you need to set up in protected resource map.
      [
        "https://api-documents-admin-t.bkw.ch",
        ["https://csi-dochub-test.azurewebsites.net/user_impersonation"],
      ],
    ] as any,
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "user.read",
        "https://csi-dochub-test.azurewebsites.net/user_impersonation",
      ],
    },
    loginFailedRoute: "./unauthorized",
  },
  apiUrl: "https://api-t.bkw.ch/apit/dochub/v1",
  opDocumentAdminApiUrl: "https://api-documents-admin-t.bkw.ch",
  netUsageAdGroup: "L_APP_D365_N_INT",
  csvMappingFilename: "TEST_sharepointCCID-mappings.csv",
  adminAdGroupId: "5cea1b6e-7fec-429b-be67-b499a54af3e1", // G_APP_DocHub_Admin
};