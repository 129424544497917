import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { OpDocAdminService } from "../../generated/op-document-admin-backend/model/service";
import { shareReplay } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable()
export class OpServicebundleService {
  private servicebundlesCache: Observable<OpDocAdminService[]>;

  constructor(private bfcConfigurationService: BfcConfigurationService, private httpClient: HttpClient) {}

  fetchServicebundles(): Observable<OpDocAdminService[]> {
    if (!this.servicebundlesCache) {
      this.servicebundlesCache = this.httpClient
        .get<OpDocAdminService[]>(this.bfcConfigurationService.configuration.opDocumentAdminApiUrl + `/servicebundles`)
        .pipe(
          shareReplay({
            refCount: false,
            bufferSize: 1,
          }),
        );
    }
    return this.servicebundlesCache;
  }
}
