<bfc-loading-overlay [loading]="showTableLoading">
  <mat-dialog-content>
    <h1>{{ "DOCHUB_ADMIN.EDIT.EDIT_DATA" | bfcTranslate }}</h1>
    <div *ngIf="errorOnSubmit">
      <mat-error>{{ "DOCHUB_ADMIN.EDIT.EDIT_DATA_DOCUMENT_ERROR" | bfcTranslate }}</mat-error>
    </div>
    <form [formGroup]="formGroup">
      <div class="bfc-form-row">
        <mat-form-field>
          <mat-label>{{ "DOCHUB_ADMIN.DOCUMENTS.LIST.COLUMNS.NAME" | bfcTranslate }}</mat-label>
          <input matInput type="text" formControlName="name" name="name" required />
        </mat-form-field>
      </div>
      <div class="bfc-form-row">
        <mat-form-field>
          <mat-label>{{ "DOCHUB_ADMIN.DOCUMENTS.LIST.COLUMNS.LANGUAGE" | bfcTranslate }}</mat-label>
          <input matInput type="text" formControlName="language" name="language" />
        </mat-form-field>
      </div>
      <div class="bfc-form-row">
        <mat-form-field class="bfc-form-field status-select" ngClass.xs="no-margin-left">
          <mat-label>{{ "DOCHUB_ADMIN.DOCUMENTS.LIST.COLUMNS.TYPE" | bfcTranslate }}</mat-label>
          <mat-select formControlName="type" (selectionChange)="selectedDocumentType = $event" required>
            <mat-option *ngFor="let documentType of documentTypes" [value]="documentType">
              {{ documentType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="bfc-form-row">
        <mat-form-field>
          <mat-label>{{ "DOCHUB_ADMIN.DOCUMENTS.LIST.COLUMNS.FILE_NAME" | bfcTranslate }}</mat-label>
          <input matInput type="text" formControlName="fileName" name="fileName" required />
        </mat-form-field>
      </div>
      <div class="bfc-form-row">
        <mat-form-field>
          <mat-label>{{ "DOCHUB_ADMIN.DOCUMENTS.LIST.COLUMNS.FILE_TYPE" | bfcTranslate }}</mat-label>
          <input matInput type="text" formControlName="fileType" name="fileType" required />
        </mat-form-field>
      </div>
    </form>
    <h4 *ngIf="metaDataEntries?.length">{{ "DOCHUB_ADMIN.EDIT.META_DATA" | bfcTranslate }}</h4>
    <form [formGroup]="metaDataFormGroup">
      <div class="bfc-form-row" *ngFor="let entry of metaDataEntries">
        <mat-form-field>
          <mat-label>{{ entry[0] }}</mat-label>
          <input matInput type="text" [formControlName]="entry[0]" [name]="entry[0]" />
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ "UPLOAD.CANCEL" | bfcTranslate }}
    </button>
    <button mat-button color="primary" class="bfc-button-hero" [disabled]="!selectedDocument" (click)="submit()">
      {{ "UPLOAD.SAVE" | bfcTranslate }}
    </button>
  </mat-dialog-actions>
</bfc-loading-overlay>
