<bfc-loading-overlay [loading]="showTableLoading">
  <mat-dialog-content>
    <h1>{{ title }}</h1>
    <form [formGroup]="formGroup">
      <div class="bfc-form-row">
        <mat-form-field>
          <mat-label>{{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.IDENTITY" | bfcTranslate }}</mat-label>
          <input matInput type="text" formControlName="identity" name="identity" required />
        </mat-form-field>
      </div>
      <div class="bfc-form-row">
        <mat-form-field class="bfc-form-field status-select" ngClass.xs="no-margin-left">
          <mat-label>{{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.IDENTITY_TYPE" | bfcTranslate }}</mat-label>
          <mat-select formControlName="identityType" required>
            <mat-option *ngFor="let identityType of identityTypes" [value]="identityType">
              {{ identityType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-checkbox
        color="primary"
        formControlName="read"
        [ngClass]="{ 'conflict-checkbox': permission && permission.read === null }"
      >
        {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.READ" | bfcTranslate }}
      </mat-checkbox>
      <mat-checkbox
        class="bfc-margin-top"
        color="primary"
        formControlName="write"
        [ngClass]="{ 'conflict-checkbox': permission && permission.write === null }"
      >
        {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.WRITE" | bfcTranslate }}
      </mat-checkbox>
      <mat-checkbox
        class="bfc-margin-top"
        color="primary"
        formControlName="changePermission"
        [ngClass]="{ 'conflict-checkbox': permission && permission.changePermission === null }"
      >
        {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.CHANGE_PERMISSION" | bfcTranslate }}
      </mat-checkbox>
      <mat-checkbox
        class="bfc-margin-top"
        color="primary"
        formControlName="inheritable"
        [ngClass]="{ 'conflict-checkbox': permission && permission.inheritable === null }"
      >
        {{ "DOCHUB_ADMIN.EDIT.PERMISSIONS.INHERITABLE" | bfcTranslate }}
      </mat-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ "UPLOAD.CANCEL" | bfcTranslate }}
    </button>
    <button mat-button color="primary" class="bfc-button-hero" (click)="submit()">
      {{ actionLabel }}
    </button>
  </mat-dialog-actions>
</bfc-loading-overlay>
