import { OverlayModule } from "@angular/cdk/overlay";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { ConfirmationDialogModule } from "./confirmation-dialog/confirmation-dialog.module";
import { ContractsUploadDialogModule } from "./contracts/upload-dialog/contracts-upload-dialog.module";
import { MsalApplicationModule } from "./core/auth/msal-application-module";
import { CoreModule } from "./core/core.module";
import { NetUsageContractsUploadDialogModule } from "./net-usage/net-usage-contracts/net-usage-upload-dialog/net-usage-contracts-upload-dialog.module";
import { DatePipe } from "@angular/common";
import { NetUsageConnectionObjectsUploadDialogModule } from "./net-usage/net-usage-connection-objects/net-usage-connection-objects-upload-dialog/net-usage-connection-objects-upload-dialog.module";
import { DocumentCollectionEditDataDialogModule } from "./dochub-admin/document-collections/document-collection-edit-data-dialog/document-collection-edit-data-dialog.module";
import { DocumentCollectionEditPermissionsDialogModule } from "./dochub-admin/document-collections/document-collection-edit-permissions-dialog/document-collection-edit-permissions-dialog.module";
import { AddOrEditPermissionDialogModule } from "./dochub-admin/add-or-edit-permission-dialog/add-or-edit-permission-dialog.module";
import { DocumentEditDataDialogModule } from "./dochub-admin/documents/documents-edit-data-dialog/document-edit-data-dialog.module";
import { DocumentEditPermissionsDialogModule } from "./dochub-admin/documents/document-edit-permissions-dialog/document-edit-permissions-dialog.module";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    CoreModule,
    ContractsUploadDialogModule,
    NetUsageContractsUploadDialogModule,
    NetUsageConnectionObjectsUploadDialogModule,
    DocumentCollectionEditDataDialogModule,
    DocumentCollectionEditPermissionsDialogModule,
    AddOrEditPermissionDialogModule,
    DocumentEditDataDialogModule,
    DocumentEditPermissionsDialogModule,
    ConfirmationDialogModule,
    OverlayModule,
    BfcSinglePageLayoutModule,
    MsalApplicationModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
