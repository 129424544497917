import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { MarketReportsUploadDialogComponent } from "../../dsv/market-reports/upload-dialog/upload-dialog.component";
import { ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";
import { ContractsUploadDialogComponent } from "../../contracts/upload-dialog/upload-dialog.component";
import { DocumentCollectionDto } from "../../generated/doc-hub/model/documentCollectionDto";
import { DocumentDto } from "../../generated/doc-hub/model/documentDto";
import { DialogTypeEnum } from "../model/dialog-type.enum";
import { SharepointParameters } from "../model/sharepoint-parameters";
import { SharepointNetUsageParameters } from "../model/sharepoint-net-usage-parameters";
import { NetUsageContractsUploadDialogComponent } from "../../net-usage/net-usage-contracts/net-usage-upload-dialog/net-usage-contracts-upload-dialog.component";
import { NetUsageConnectionObjectsUploadDialogComponent } from "../../net-usage/net-usage-connection-objects/net-usage-connection-objects-upload-dialog/net-usage-connection-objects-upload-dialog.component";
import { DocumentCollectionEditDataDialogComponent } from "../../dochub-admin/document-collections/document-collection-edit-data-dialog/document-collection-edit-data-dialog.component";
import { DocumentCollectionEditPermissionsDialogComponent } from "../../dochub-admin/document-collections/document-collection-edit-permissions-dialog/document-collection-edit-permissions-dialog.component";
import { Injectable } from "@angular/core";

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  public openUploadDialog(
    selectedCollection: DocumentCollectionDto,
    dialogType: DialogTypeEnum,
    callBackOnSuccess: () => void,
    document?: DocumentDto,
    sharepointParameters?: SharepointParameters,
    sharepointNetUsageParameters?: SharepointNetUsageParameters,
  ) {
    switch (dialogType) {
      case DialogTypeEnum.CONTRACTS_UPLOAD:
        this.openContractsUploadDialog(selectedCollection, callBackOnSuccess, document, sharepointParameters);
        break;
      case DialogTypeEnum.DSV_MARKET_REPORTS_UPLOAD:
        this.openMarketReportsUploadDialog(selectedCollection, callBackOnSuccess, document);
        break;
      case DialogTypeEnum.NET_USAGE_CONTRACTS_DIALOG:
        this.openNetUsageContractsUploadDialog(
          selectedCollection,
          callBackOnSuccess,
          document,
          sharepointNetUsageParameters,
        );
        break;
      case DialogTypeEnum.NET_USAGE_CONNECTION_OBJECTS_DIALOG:
        this.openNetUsageConnectionObjectsUploadDialog(
          selectedCollection,
          callBackOnSuccess,
          document,
          sharepointNetUsageParameters,
        );
        break;
      case DialogTypeEnum.DOCHUB_ADMIN_COLLECTION_EDIT_DATA_DIALOG:
        this.openDocumentCollectionEditDataDialogComponent(selectedCollection, callBackOnSuccess);
        break;
      case DialogTypeEnum.DOCHUB_ADMIN_COLLECTION_EDIT_PERMISSIONS_DIALOG:
        this.openDocumentCollectionEditPermissionDialogComponent(selectedCollection, callBackOnSuccess);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error("wrong dialog type.");
        break;
    }
  }

  private openContractsUploadDialog(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
    document?: DocumentDto,
    sharepointParameters?: SharepointParameters,
  ) {
    return this.matDialog.open(ContractsUploadDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
        document: document,
        sharepointParameters: sharepointParameters,
      },
    });
  }

  private openNetUsageContractsUploadDialog(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
    document?: DocumentDto,
    sharepointNetUsageParameters?: SharepointNetUsageParameters,
  ) {
    return this.matDialog.open(NetUsageContractsUploadDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
        document: document,
        sharepointNetUsageParameters: sharepointNetUsageParameters,
      },
    });
  }

  private openNetUsageConnectionObjectsUploadDialog(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
    document?: DocumentDto,
    sharepointNetUsageParameters?: SharepointNetUsageParameters,
  ) {
    return this.matDialog.open(NetUsageConnectionObjectsUploadDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
        document: document,
        sharepointNetUsageParameters: sharepointNetUsageParameters,
      },
    });
  }

  private openDocumentCollectionEditDataDialogComponent(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
  ) {
    return this.matDialog.open(DocumentCollectionEditDataDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
      },
    });
  }

  private openDocumentCollectionEditPermissionDialogComponent(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
  ) {
    return this.matDialog.open(DocumentCollectionEditPermissionsDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
      },
    });
  }

  private openMarketReportsUploadDialog(
    selectedCollection: DocumentCollectionDto,
    callBackOnSuccess: () => void,
    document?: DocumentDto,
  ) {
    return this.matDialog.open(MarketReportsUploadDialogComponent, {
      data: {
        selectedCollection: selectedCollection,
        callBackOnSuccess: callBackOnSuccess,
        document: document,
      },
    });
  }

  public openConfirmationDialog(title: string, description: string): Observable<boolean> {
    return this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: title,
          description: description,
        },
      })
      .afterClosed();
  }
}
