export const FR_TRANSLATIONS = {
  SERVICE_NAME: "Documents Admin",
  OVERVIEW: {
    TITLE: "Admin-Bereich Dokumente",
    SUBTITLE:
        "Administrationsbereich für alle Dokumente des Kunden mit der BKW: Verträge, EVU-Vorlagen, IT@AÄB Dokumente",
    CONTRACTS: "Verträge",
    CONTRACTS_SUBTITLE: "Aktive und erfüllte Verträge",
    CONTRACTS_LINK: "Zu den Verträgen",
    NET_USAGE: "Utilisation du réseau",
    NET_USAGE_SUBTITLE: "Documents sur l'utilisation du réseau",
    NET_USAGE_LINK: "Vers les documents d'utilisation du réseau",
    DOCHUB_ADMIN: "Collections et documents de l'administration",
    DOCHUB_ADMIN_SUBTITLE: "Administrer des collections de documents et des documents de référence",
    DOCHUB_ADMIN_LINK: "Pour l'administration de DocHub",
    B2B_EXPORT: "OP B2B Export",
    B2B_EXPORT_SUBTITLE: "B2B Export-Dateien aus dem OP Admintool.",
    B2B_EXPORT_LINK: "Zu den Exports",
    DSV: {
      MARKET_REPORTS: {
        TITLE: "Rapports de marché",
        SUBTITLE:
          "Les rapports de marché, qui sont mis à la disposition du client dans les informations sur le marché.",
        LINK: "Zu den Dokumenten",
      },
    },
  },
  CONTRACTS: {
    LIST: {
      COLUMNS: {
        CUSTOMER_NAME: "Nom du client",
        CUSTOMER_NUMBER: "Numéro du client",
        QUOTE_NUMBER: "Numéro de l’offre",
        CONTRACT_NUMBER: "Numéro de contrat",
        NAME: "Nom",
        START: "Début de contrat",
        END: "Fin de contrat",
        STATUS: "Statut",
        DOWNLOAD: "Téléchargement",
        EDIT: "Modifier les donées de contrat",
        DELETE: "Effacer le contrat",
      },
      SEARCH_CUSTOMER: "Chercher clients",
      SEARCH: "Chercher documents",
      EDIT_SUCCESSFUL: "Le contrat était actualisé avec succès.",
      EDIT_SUCCESSFUL_CRM:
        "Le contrat était actualisé avec succès. L'ajustement du contrat ne sera pas synchronisé dans le CRM.",
      EDIT_ERROR: "Le contrat n'as pas pu être actualisé.",
      DELETE_SUCCESSFUL: "Le contrat était effacé avec succès.",
      DELETE_SUCCESSFUL_CRM:
        "Le contrat était effacé avec succès. L'ajustement du contrat ne sera pas synchronisé dans le CRM.",
      DELETE_ERROR: "Le contrat n'as pas pu être effacé.",
      STATUS: {
        PLACEHOLDER: "Statut",
        ACTIVE: "Actif",
        FULFILLED: "Rempli",
        ALL: "Voir tout",
      },
    },
    UPLOAD: {
      BUTTON: "Télécharger le contrat",
      TOOLTIP: "Vous devez d'abord chercher et sélectionner un client",
      FILE_READING_ERROR: "Lire le fichier choisi est échoué.",
      UPLOAD_ERROR: "Le contrat n'as pas pu être téléchargé.",
      UPLOAD_SUCCESSFUL: "Le contrat était téléchargé avec succès.",
      SHARE_POINT_PARAMS_INVALID: "Les paramètres SharePoint fournis ne sont pas valides.",
      DIALOG: {
        TITLE: {
          UPLOAD: "Télécharger le contrat",
          EDIT: "Modifier le contrat",
        },
        CUSTOMER: "Client",
        SUBMIT: "Exécuter",
        CANCEL: "Avorter",
        SELECT_FILE: "Choisir le fichier",
        NO_FILE_SELECTED: "Aucun fichier est choisi",
      },
      GRATIS_PERMISSION: "Kein Bundle - Default",
    },
    EDIT_SUCCESSFUL: "Die Dokumentvorlage wurde erfolgreich aktualisiert.",
    CONFIRMATION: {
      DELETE: {
        TITLE: "Effacer le contrat",
        DESCRIPTION: "Voulez-vous vraiment effacer le contrat?",
      },
    },
  },
  B2B_EXPORT: {
    TITLE: "B2B Export",
    SEARCH: "Suchbegriff eingeben",
    CONFIRMATION: {
      DELETE: {
        TITLE: "B2B Export löschen",
        DESCRIPTION: "Wollen Sie den B2B Export wirklich löschen?",
      },
    },
    LIST: {
      DELETE_SUCCESSFUL: "Der B2B Export wurde erfolgreich gelöscht.",
      DELETE_ERROR: "Der B2B Export konnte nicht gelöscht werden.",
      COLUMNS: {
        NAME: "Name",
        MUT_DATE: "Aktualisiert",
        CREATED_DATE: "Hochgeladen",
        DOWNLOAD: "Download",
        DELETE: "B2B Export löschen",
      },
    },
  },
  MARKET_REPORTS: {
    DSV_MARKET_REPORT_TYPE: "Type de rapport de marché",
    DSV_MARKET_REPORT_TYPES: {
      KMU_REPORT: "Rapport mensuel sur le marché",
      WEEKLY_REPORT: "Rapport hebdomadaire sur le marché",
      MONTHLY_RADAR: "Radar mensuel des prix de l'énergie",
    },
    LIST: {
      COLUMNS: {
        NAME: "Nom",
        CREATED_ON: "Téléchargé sur",
        DOWNLOAD: "Télécharger",
        DELETE: "Effacer",
      },
      DELETE_ERROR: "Le rapport de marché n'a pas pu être supprimé.",
      DELETE_SUCCESS: "Le rapport sur le marché a été supprimé.",
    },
    UPLOAD: {
      BUTTON: "Télécharger le rapport de marché",
      DIALOG: {
        TITLE: "Rapport sur le marché",
        CANCEL: "Annuler",
        SUBMIT: "Télécharger",
        SELECT_FILE: "Sélectionnez un fichier",
        NO_FILE_SELECTED: "Aucun fichier sélectionné",
      },
      UPLOAD_SUCCESSFUL: "Le rapport de marché a été téléchargé avec succès.",
      UPLOAD_ERROR: "Le rapport de marché n'a pas pu être téléchargé.",
      FILE_READING_ERROR: "Le rapport de marché n'a pas pu être téléchargé.",
    },
    CONFIRMATION: {
      DELETE: {
        TITLE: "Supprimer le rapport de marché",
        DESCRIPTION: "Veulent-ils vraiment supprimer le rapport sur le marché ?",
      },
    },
    INVALID_DATE: "Date non valable",
  },
  DIALOG: {
    BUTTON: {
      SUBMIT: "Oui",
      CANCEL: "Avorter",
    },
  },
  NET_USAGE: {
    CONTRACTS_LABEL: "Contrats",
    CONTRACTS: {
      LIST: {
        COLUMNS: {
          CONTRACT_NUMBER: "Numéro de contrat",
          CONTRACT_NAME: "Nom du contrat",
          CONNECTION_OBJECT: "Objet de la connexion",
          OWNER: "Propriétaire",
          START: "Début de contrat",
          DOWNLOAD: "Télécharger",
          DELETE: "Effacer",
        },
        SEARCH_OWNER: "Recherche de propriétaires",
      },
    },
    CONNECTION_OBJECTS_LABEL: "Objets de connexion",
    CONNECTION_OBJECTS: {
      LIST: {
        COLUMNS: {
          NAME: "Nom du fichier",
          OWNER: "Propriétaire",
          CREATED: "Créé le",
          DOWNLOAD: "Télécharger",
          EDIT: "Modifier les données du document de l'objet de connexion",
          DELETE: "Effacer",
        },
        SEARCH_OWNER: "Recherche de propriétaires",
        EDIT_SUCCESSFUL: "Le document de l'objet de connexion a été mis à jour avec succès.",
        EDIT_SUCCESSFUL_CRM:
          "Le document de l'objet de connexion a été mis à jour avec succès. Les ajustements des documents de l'objet de connexion ne sont pas synchronisés avec le CRM.",
        EDIT_ERROR: "Le document de l'objet de connexion n'a pas pu être mis à jour.",
        DELETE_SUCCESSFUL: "Le document de l'objet de connexion a été supprimé avec succès.",
        DELETE_SUCCESSFUL_CRM:
          "Le document de l'objet de connexion a été supprimé avec succès. Les ajustements des documents de l'objet de connexion ne sont pas synchronisés dans le CRM.",
        DELETE_ERROR: "Le document de l'objet de connexion n'a pas pu être supprimé.",
      },
      UPLOAD: {
        UPLOAD_ERROR: "Le document de l'objet de connexion n'a pas pu être téléchargé.",
        UPLOAD_SUCCESSFUL: "Le document de l'objet de connexion a été téléchargé avec succès.",
      },
      DIALOG: {
        TITLE: {
          UPLOAD: "Télécharger le document de l'objet de connexion",
          EDIT: "Modifier le document de l'objet de connexion",
        },
      },
      CONFIRMATION: {
        DELETE: {
          TITLE: "Supprimer le document de l'objet de connexion",
          DESCRIPTION: "Voulez-vous vraiment supprimer le document de l'objet de connexion?",
        },
      },
    },
    OWNER: "Propriétaire",
  },
  DOCHUB_ADMIN: {
    DOCUMENT_COLLECTIONS_LABEL: "Collections",
    DOCUMENTS_LABEL: "Documents",
    DOCUMENT_COLLECTIONS: {
      LIST: {
        COLUMNS: {
          COLLECTION_NAME: "Nom",
          CUSTOMER_NUMBER: "Numéro de client",
          EDIT_DATA: "Personnaliser les données",
          EDIT_PERMISSIONS: "Adapter les autorisations",
        },
        SEARCH_COLLECTION: "recherche de collections",
        MULTIPLE_EDIT_PERMISSIONS: "Ajuster les permissions des collections sélectionnées",
        COUNT_SELECTED_COLLECTIONS: "Nombre de collections sélectionnées: {{number}}",
      },
    },
    DOCUMENTS: {
      LIST: {
        SEARCH_CUSTOMER: "Recherche client/propriétaire",
        SEARCH_DOCUMENT: "Document de recherche",
        SEARCH_PERMISSION: "Recherche d'une autorisation (recherche avec Enter)",
        MULTIPLE_EDIT_PERMISSIONS: "Ajuster les permissions des documents sélectionnés",
        COUNT_SELECTED_DOCUMENTS: "Nombre de documents sélectionnés: {{number}}",
        COLUMNS: {
          NAME: "Nom",
          LANGUAGE: "Langue",
          CUSTOMER: "Client/propriétaire",
          TYPE: "Type de document",
          FILE_NAME: "Nom du fichier",
          FILE_TYPE: "Type de fichier",
        },
      },
    },
    EDIT: {
      EDIT_DATA: "Personnaliser les données",
      EDIT_PERMISSIONS: "Adapter les autorisations",
      DOCUMENT: "Document",
      META_DATA: "MetaData",
      CUSTOMER_NUMBER: "customerNumber",
      ADD_PERMISSION: "Ajouter une autorisation",
      EDIT_PERMISSION: "Modifier l'autorisation",
      EDIT_DATA_ERROR: "Les données de collecte des documents n'ont pas pu être mises à jour.",
      EDIT_DATA_SUCCESSFUL: "Les données de la collection de documents ont été mises à jour avec succès.",
      EDIT_DATA_DOCUMENT_ERROR: "Les données du document n'ont pas pu être mises à jour.",
      EDIT_DATA_DOCUMENT_SUCCESSFUL: "Les données du document ont été mises à jour avec succès.",
      EDIT_PERMISSIONS_ERROR: "Les autorisations de la collection de documents n'ont pas pu être mises à jour.",
      EDIT_PERMISSIONS_SUCCESSFUL: "Les autorisations de la collection de documents ont été mises à jour avec succès.",
      EDIT_PERMISSIONS_DOCUMENT_ERROR: "Les autorisations du document n'ont pas pu être mises à jour.",
      EDIT_PERMISSIONS_DOCUMENT_SUCCESSFUL: "Les autorisations du document ont été mises à jour avec succès.",
      PERMISSIONS: {
        IDENTITY: "Identity",
        IDENTITY_TYPE: "Identity Type",
        READ: "Read",
        WRITE: "Write",
        CHANGE_PERMISSION: "Change Permission",
        INHERITABLE: "Inheritable",
        IS_INHERITED: "Is Inherited",
        EDIT: "Modifier",
        NOTE: "REMARQUE : seules les autorisations communes sont affichées",
        CONFIRMATION: {
          TITLE: "Supprimer l'autorisation",
          DESCRIPTION: "Voulez-vous vraiment supprimer l'autorisation?",
        },
      },
      ERROR: {
        LOAD_ERROR_COLLECTION_PERMISSIONS:
          "Les autorisations de la collection de documents n'ont pas pu être chargées.",
        LOAD_ERROR_DOCUMENT_PERMISSIONS: "Les autorisations du document n'ont pas pu être chargées.",
      },
    },
  },
  UPLOAD: {
    SUBMIT: "Exécuter",
    SAVE: "Sauvez",
    ADD: "Ajouter",
    CANCEL: "Avorter",
    SELECT_FILE: "Choisir le fichier",
    NO_FILE_SELECTED: "Aucun fichier est choisi",
  },
  ERROR: {
    LOAD_ERROR_UNAUTHORIZED: "Vous n'avez pas d'autorisation pour utiliser l'application.",
    OPERATION_ERROR_UNAUTHORIZED: "Vous n'avez pas d'autorisation pour exécuter cette operation.",
    LOAD_ERROR_DOCUMENTS: "Les documents n'ont pas pu être chargés ou seulement partiellement.",
    LOAD_ERROR_COLLECTIONS: "La collection de documents n'a pas pu être chargé.",
    FILE_DOWNLOAD_ERROR: "Le document n'a pas pu être téléchargé.",
    COLLECTION_CREATE: {
      GENERAL: "Eine neue Dokument-Collection konnte nicht erstellt werden. Ein unbekannter Fehler ist aufgetreten. FR",
      NOT_FOUND:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Es wurde keine Organisation mit der CRM ID {{crmNumber}} gefunden. FR",
      CONFLICT:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Für die Organisation mit der CRM ID {{crmNumber}} existiert bereits eine Collection. FR",
      UNAUTHORIZED:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Sie haben nicht die passende Berechtigung, um diese Operation auszuführen. FR",
    },
  },
  BACK_BUTTON: "Retour à la vue d'ensemble",
};
