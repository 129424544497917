import { ErrorHandler, Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";

@Injectable()
export class ErrorService implements ErrorHandler {
  constructor(private notificationService: BfcNotificationService) {}

  handleError(error: unknown, message?: string) {
    this.showError(message);
    if (!message) {
      // in case of unexpected technical errors
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  showError(message: string) {
    this.notificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: !!message ? message : "Technical Error!",
      options: {
        duration: 0,
      },
    });
  }
}
