<bfc-loading-overlay [loading]="showTableLoading">
  <mat-dialog-content>
    <h1>{{ "CONTRACTS.UPLOAD.DIALOG.TITLE." + getTitleSuffix() | bfcTranslate }}</h1>

    <form [formGroup]="formGroup">
      <div class="bfc-form-row bfc-padding-top-1 customer-name">
        {{ "CONTRACTS.UPLOAD.DIALOG.CUSTOMER" | bfcTranslate }}: <b>{{ getCustomer() }}</b>
      </div>

      <div *ngIf="!!sharepointNetUsageParameters" class="bfc-form-row">
        <div style="word-wrap: break-word">
          {{ sharepointNetUsageParameters.documentPath }}
        </div>
        <div *ngIf="errorOnSubmit">
          <mat-error>{{ "CONTRACTS.UPLOAD.UPLOAD_ERROR" | bfcTranslate }}</mat-error>
        </div>
      </div>

      <div *ngIf="isDocumentAvailable()">
        <div *ngIf="!!documentForEditing && errorOnSubmit" class="bfc-padding-bottom">
          <mat-error>{{ "CONTRACTS.LIST.EDIT_ERROR" | bfcTranslate }}</mat-error>
        </div>

        <div class="bfc-form-row">
          <mat-form-field>
            <mat-label>{{ "NET_USAGE.CONTRACTS.LIST.COLUMNS.CONTRACT_NUMBER" | bfcTranslate }}</mat-label>
            <input matInput type="text" formControlName="contractNumber" name="contractNumber" required />
          </mat-form-field>
        </div>
        <div class="bfc-form-row">
          <mat-form-field>
            <mat-label>{{ "NET_USAGE.CONTRACTS.LIST.COLUMNS.CONTRACT_NAME" | bfcTranslate }}</mat-label>
            <input matInput type="text" formControlName="name" name="contractName" required />
          </mat-form-field>
        </div>
        <div class="bfc-form-row">
          <mat-form-field>
            <mat-label>{{ "NET_USAGE.CONTRACTS.LIST.COLUMNS.CONNECTION_OBJECT" | bfcTranslate }}</mat-label>
            <input matInput type="text" formControlName="connectionObject" name="connectionObject" required />
          </mat-form-field>
        </div>
        <div class="bfc-form-row">
          <mat-form-field>
            <mat-label>{{ "NET_USAGE.CONTRACTS.LIST.COLUMNS.START" | bfcTranslate }}</mat-label>
            <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate" name="startDate" required />
            <mat-datepicker-toggle matSuffix [for]="pickerStartDate">
              <mat-icon matDatepickerToggleIcon>
                <bfe-icon name="bfc-icon-calendar"></bfe-icon>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerStartDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ "CONTRACTS.UPLOAD.DIALOG.CANCEL" | bfcTranslate }}
    </button>
    <button mat-button color="primary" class="bfc-button-hero" [disabled]="!isDocumentAvailable()" (click)="submit()">
      {{ "CONTRACTS.UPLOAD.DIALOG.SUBMIT" | bfcTranslate }}
    </button>
  </mat-dialog-actions>
</bfc-loading-overlay>
