import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";
import { IDocumentsAdminAppConfiguration } from "../configuration";
import { IS_IE } from "./general";

export const P_ENV: IDocumentsAdminAppConfiguration = {
  msalConfig: {
    auth: {
      clientId: "ea9267d6-303d-473a-8b09-c8ee2dfd01fc", //  csi-dochub-api-test-admingui
      authority:
        "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: "https://admin-documents.bkw.ch",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE,
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [
      [
        "https://api.bkw.ch",
        ["https://csi-dochub-prod.azurewebsites.net/user_impersonation"],
      ],
      // The best way is to register the own client (in this case op-admin-documents-backend) in azure.
      // But if you only use pure jwt validation, you need to set up in protected resource map.
      [
        "https://api-documents-admin.bkw.ch",
        ["https://csi-dochub-prod.azurewebsites.net/user_impersonation"],
      ],
    ] as any,
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "user.read",
        "https://csi-dochub-prod.azurewebsites.net/user_impersonation",
      ],
    },
    loginFailedRoute: "./unauthorized",
  },
  apiUrl: "https://api.bkw.ch/api/dochub/v1",
  opDocumentAdminApiUrl: "https://api-documents-admin.bkw.ch",
  netUsageAdGroup: "L_APP_D365_N",
  csvMappingFilename: "PROD_sharepointCCID-mappings.csv",
  adminAdGroupId: "39d42ef0-9fb8-4e0b-a272-717338eb7e80", // G_APP_DocHub_Admin
};
