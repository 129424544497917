import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { RoleGuard } from "./core/auth/role-guard.service";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  {
    path: "",
    redirectTo: "overview",
    pathMatch: "full",
  },
  {
    path: "overview",
    loadChildren: () => import("./overview/overview.module").then(m => m.OverviewModule),
    canActivate: [MsalGuard],
  },
  {
    path: "dsv",
    loadChildren: () => import("./dsv/dsv-documents.module").then(m => m.DsvDocumentsModule),
    canActivate: [MsalGuard],
  },
  {
    path: "net-usage",
    loadChildren: () => import("./net-usage/net-usage.module").then(m => m.NetUsageModule),
    canActivate: [MsalGuard],
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "dochub-admin",
    loadChildren: () => import("./dochub-admin/dochub-admin.module").then(m => m.DochubAdminModule),
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
