import { Component, Inject, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import { BfcTranslationService } from "@bfl/components/translation";
import { PermissionAttributeEnum } from "../../core/model/permission-attribute.enum";
import { IdentityType } from "../../generated/doc-hub/model/identityType";
import { PermissionDto } from "../../generated/doc-hub/model/permissionDto";

@Component({
  selector: "app-add-or-edit-permission-dialog",
  templateUrl: "./add-or-edit-permission-dialog.component.html",
  styleUrls: ["./add-or-edit-permission-dialog.component.scss"],
})
export class AddOrEditPermissionDialogComponent implements OnInit {
  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  permission: PermissionDto;

  identityTypes: string[] = Object.keys(IdentityType);

  formGroup: FormGroup;

  showTableLoading = false;

  errorOnSubmit = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      permission: PermissionDto;
    },
    private dialogRef: MatDialogRef<AddOrEditPermissionDialogComponent>,
    private formBuilder: FormBuilder,
    private bfcTranslationService: BfcTranslationService,
  ) {}

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;

      const permission: PermissionDto = {
        identity: this.formGroup.get("identity").value,
        identityType: this.formGroup.get("identityType").value,
        read: this.formGroup.get("read").value,
        write: this.formGroup.get("write").value,
        changePermission: this.formGroup.get("changePermission").value,
        inheritable: this.formGroup.get("inheritable").value,
        isInherited: this.permission ? this.permission.isInherited : false, // is a system field
      };

      this.dialogRef.close(permission);
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  ngOnInit(): void {
    this.permission = this.data?.permission;
    this.formGroup = this.formBuilder.group({
      identity: [this.permission ? this.permission.identity : "", [Validators.required]],
      identityType: [this.permission ? this.permission.identityType : "", [Validators.required]],
      read: this.attributeValue(PermissionAttributeEnum.READ),
      write: this.attributeValue(PermissionAttributeEnum.WRITE),
      changePermission: this.attributeValue(PermissionAttributeEnum.CHANGE_PERMISSION),
      inheritable: this.attributeValue(PermissionAttributeEnum.INHERITABLE),
    });
  }

  get title(): string {
    return this.bfcTranslationService.translate(
      this.permission ? "DOCHUB_ADMIN.EDIT.EDIT_PERMISSION" : "DOCHUB_ADMIN.EDIT.ADD_PERMISSION",
    );
  }

  get actionLabel(): string {
    return this.bfcTranslationService.translate(this.permission ? "UPLOAD.SAVE" : "UPLOAD.ADD");
  }

  attributeValue(permissionAttribute: PermissionAttributeEnum): boolean {
    // if Editing, for read, write and changePermission the default value if there is a conflict is true,
    //  for inheritable false
    let value;

    switch (permissionAttribute) {
      case PermissionAttributeEnum.READ: {
        if (this.permission) {
          value = this.permission.read !== null ? this.permission.read : true;
        } else {
          value = false;
        }
        break;
      }
      case PermissionAttributeEnum.WRITE: {
        if (this.permission) {
          value = this.permission.write !== null ? this.permission.write : true;
        } else {
          value = false;
        }
        break;
      }
      case PermissionAttributeEnum.CHANGE_PERMISSION: {
        if (this.permission) {
          value = this.permission.changePermission !== null ? this.permission.changePermission : true;
        } else {
          value = false;
        }
        break;
      }
      case PermissionAttributeEnum.INHERITABLE: {
        value = this.permission && this.permission.inheritable !== null ? this.permission.inheritable : false;
        break;
      }
    }

    return value;
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
