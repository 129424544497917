//sadly necessary for this file. Maybe try removing it in the future and see if there are still linting errors.
/* eslint-disable @typescript-eslint/indent */
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { DocumentCollectionDto } from "../../generated/doc-hub/model/documentCollectionDto";
import { DocumentDto } from "../../generated/doc-hub/model/documentDto";
import { DocumentType } from "../../generated/doc-hub/model/documentType";
import { InsertDocumentCollectionDto } from "../../generated/doc-hub/model/insertDocumentCollectionDto";
import { InsertDocumentDto } from "../../generated/doc-hub/model/insertDocumentDto";
import { InsertSharepointDocumentDto } from "../../generated/doc-hub/model/insertSharepointDocumentDto";
import { PermissionDto } from "../../generated/doc-hub/model/permissionDto";
import { fileSaver } from "../common/file-saver";
import { CustomerDocument } from "../model/customer-document";
import { DocumentPatchOperation } from "../model/document-patch-operation";
import { DocumentServiceCodeEnum } from "../model/document-service-code.enum";
import { ParameterType } from "../model/parameter-type";
import { Injectable } from "@angular/core";

@Injectable()
export class DocumentService {
  private documentCollectionsCachePerMasterSystem: Map<string, Observable<DocumentCollectionDto[]>> = new Map();

  private readonly NO_MASTERSYSTEM: string = "NONE";

  constructor(private bfcConfigurationService: BfcConfigurationService, private httpClient: HttpClient) {}

  fetchDocumentCollections(masterSystem?: string): Observable<DocumentCollectionDto[]> {
    if (!masterSystem) {
      masterSystem = this.NO_MASTERSYSTEM;
    }
    if (
      !this.documentCollectionsCachePerMasterSystem ||
      !this.documentCollectionsCachePerMasterSystem.get(masterSystem)
    ) {
      this.loadDocumentCollections(masterSystem);
    }
    return this.documentCollectionsCachePerMasterSystem.get(masterSystem);
  }

  loadDocumentCollections(masterSystem: string): void {
    if (masterSystem !== this.NO_MASTERSYSTEM) {
      this.documentCollectionsCachePerMasterSystem.set(
        masterSystem,
        this.httpClient
          .get<DocumentCollectionDto[]>(
            this.bfcConfigurationService.configuration.apiUrl +
              `/DocumentCollections?filter=system eq '${masterSystem}'`,
          )
          .pipe(
            shareReplay({
              refCount: false,
              bufferSize: 1,
            }),
          ),
      );
    } else {
      this.documentCollectionsCachePerMasterSystem.set(
        masterSystem,
        this.httpClient
          .get<DocumentCollectionDto[]>(this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections`)
          .pipe(
            shareReplay({
              refCount: false,
              bufferSize: 1,
            }),
          ),
      );
    }
  }

  findDocumentCollectionByCustomerIdAndMasterSystem(
    customerId: string,
    masterSystem: string,
  ): Observable<DocumentCollectionDto> {
    const parameterType = ParameterType.getParameterTypeByValue(customerId, masterSystem);
    if (parameterType === ParameterType.MSD_NET_GPNUMBER) {
      const gpNumberAttribute = parameterType.docHubAttribute;
      return this.httpClient
        .get<DocumentCollectionDto>(
          this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${gpNumberAttribute}${customerId}`,
        )
        .pipe(
          shareReplay({
            refCount: false,
            bufferSize: 1,
          }),
        );
    }
    return this.httpClient
      .get<DocumentCollectionDto>(
        this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${masterSystem}:${customerId}`,
      )
      .pipe(
        shareReplay({
          refCount: false,
          bufferSize: 1,
        }),
      );
  }

  findDocumentCollectionsByName(name: string): Observable<DocumentCollectionDto[]> {
    //Do not use cache or fetching documents for evu and itaaeb will not work
    return this.httpClient
      .get<DocumentCollectionDto[]>(
        this.bfcConfigurationService.configuration.apiUrl + "/DocumentCollections?filter=Name eq '" + name + "'",
      )
      .pipe(
        shareReplay({
          refCount: false,
          bufferSize: 1,
        }),
      );
  }

  fetchDocuments(collection: DocumentCollectionDto): Observable<DocumentDto[]> {
    return this.httpClient.get<DocumentDto[]>(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${collection.id}/Documents`,
    ).pipe(
      //reversing the array to have the newest document on top
      map((documents: DocumentDto[]) => {return documents.reverse();}),
    );
  }

  fetchCustomerDocuments(collection: DocumentCollectionDto): Observable<CustomerDocument[]> {
    return this.httpClient
      .get<DocumentDto[]>(
        this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${collection.id}/Documents`,
      )
      .pipe(
        map((documents: DocumentDto[]) => {
          return documents.map((document: DocumentDto) => {
            return DocumentService.mapToCustomerDocument(collection, document);
          });
        }),
        //reversing the array to have the newest document on top
        map((documents: CustomerDocument[]) => {return documents.reverse();}),
      );
  }

  fetchCustomerDocumentsByTypeAndServiceCode(
    collection: DocumentCollectionDto,
    type: DocumentType,
    serviceCode: DocumentServiceCodeEnum,
  ): Observable<CustomerDocument[]> {
    return this.httpClient
      .get<DocumentDto[]>(
        this.bfcConfigurationService.configuration.apiUrl +
          `/DocumentCollections/${collection.id}/Documents?filter=type eq '${type}' and authorization.permissions having (identity eq '${serviceCode}')`,
      )
      .pipe(
        map((documents: DocumentDto[]) => {
          return documents.map((document: DocumentDto) => {
            return DocumentService.mapToCustomerDocument(collection, document);
          });
        }),
      );
  }

  fetchCustomerDocumentsByPermission(
    collection: DocumentCollectionDto,
    permission: string,
  ): Observable<CustomerDocument[]> {
    return this.httpClient
      .get<DocumentDto[]>(
        this.bfcConfigurationService.configuration.apiUrl +
          `/DocumentCollections/${collection.id}/Documents?filter=authorization.permissions having (identity eq '${permission}')`,
      )
      .pipe(
        map((documents: DocumentDto[]) => {
          return documents.map((document: DocumentDto) => {
            return DocumentService.mapToCustomerDocument(collection, document);
          });
        }),
      );
  }

  fetchDocumentCollectionPermissions(collection: DocumentCollectionDto): Observable<PermissionDto[]> {
    return this.httpClient.get<PermissionDto[]>(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${collection.id}/Permissions`,
    );
  }

  fetchDocumentPermissions(document: DocumentDto): Observable<PermissionDto[]> {
    return this.httpClient.get<PermissionDto[]>(
      this.bfcConfigurationService.configuration.apiUrl + `/Documents/${document.id}/Permissions`,
    );
  }

  /**
   * finds a collection with prefix 'cid', 'msdynamics-accountnumber' or 'msdynamics-customernumber' in the
   * collections attribute 'customerId'.
   * see https://bkwiki.bkw-fmb.ch/pages/viewpage.action?pageId=120928547
   * @param customerIdentification
   */
  findCollectionByCustomerIdentification(
    customerIdentification: string,
    masterSystem: string,
  ): Observable<DocumentCollectionDto> {
    return this.fetchDocumentCollections(masterSystem).pipe(
      map((collections) => {
        return collections.find((col) => this.getUniqueCustomerNameFromCollection(col) === customerIdentification);
      }),
    );
  }

  findCollectionDependingParameterType(
    parameterValue: string,
    masterSystem: string,
  ): Observable<DocumentCollectionDto> {
    const parameterWithoutPrefix: string = ParameterType.getValueWithoutPrefix(parameterValue, masterSystem);
    const parameterType: ParameterType = ParameterType.getParameterTypeByValue(parameterValue, masterSystem);

    return this.fetchDocumentCollections(masterSystem).pipe(
      map((collections) => {
        return collections.find((col) => {
          return (
            (parameterType === ParameterType.MSD_CUSTOMER_ID && col.customerId === +parameterWithoutPrefix) ||
            col.metaData?.customerNumber === parameterWithoutPrefix
          );
        });
      }),
    );
  }

  downloadFile(document: DocumentDto): Observable<void> {
    return this.httpClient
      .get<Blob>(this.bfcConfigurationService.configuration.apiUrl + `/Documents/${document.id}/File`, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          if (!!response && !!response.body) {
            fileSaver.saveAs(response.body, document.filename);
          }
        }),
      );
  }

  updateDocumentCollection(documentCollection: DocumentCollectionDto): Observable<any> {
    return this.httpClient.put(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${documentCollection.id}`,
      documentCollection,
    );
  }

  updateDocumentCollectionPermissions(
    documentCollection: DocumentCollectionDto,
    patchOperations: DocumentPatchOperation[],
  ): Observable<any> {
    return this.httpClient.patch(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${documentCollection.id}/Permissions`,
      patchOperations,
      { headers: new HttpHeaders().set("content-type", "application/json-patch+json") },
    );
  }

  uploadDocument(newDocument: InsertDocumentDto, collection: DocumentCollectionDto): Observable<any> {
    return this.httpClient.post(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${collection.id}/Documents`,
      newDocument,
    );
  }

  uploadSharePointDocument(
    newDocument: InsertSharepointDocumentDto,
    collection: DocumentCollectionDto,
  ): Observable<any> {
    return this.httpClient.post(
      this.bfcConfigurationService.configuration.apiUrl + `/DocumentCollections/${collection.id}/SharepointDocuments`,
      newDocument,
    );
  }

  updateDocument(document: DocumentDto, patchOperations: DocumentPatchOperation[]): Observable<any> {
    return this.httpClient.patch(
      this.bfcConfigurationService.configuration.apiUrl + `/Documents/${document.id}`,
      patchOperations,
      { headers: new HttpHeaders().set("content-type", "application/json-patch+json") },
    );
  }

  updateDocumentPermissions(document: DocumentDto, patchOperations: DocumentPatchOperation[]): Observable<any> {
    return this.httpClient.patch(
      this.bfcConfigurationService.configuration.apiUrl + `/Documents/${document.id}/Permissions`,
      patchOperations,
      { headers: new HttpHeaders().set("content-type", "application/json-patch+json") },
    );
  }

  deleteDocument(document: DocumentDto): Observable<any> {
    return this.httpClient.delete(this.bfcConfigurationService.configuration.apiUrl + `/Documents/${document.id}`);
  }

  getUniqueCustomerNameFromCustomerDocument(customerDocument: CustomerDocument): string {
    return DocumentService.getUniqueCustomerName(customerDocument.customerName, customerDocument.customerNumber);
  }

  getUniqueCustomerNameFromCollection(collection: DocumentCollectionDto): string {
    return DocumentService.getUniqueCustomerName(
      collection.name,
      collection.metaData ? collection.metaData.customerNumber : null,
    );
  }

  /**
   * the new field 'customerId' is optional.
   * if you use it for M2C Contracts, it is mandatory!
   * see https://bkwiki.bkw-fmb.ch/display/EAI/CCID+Umbau
   * @param parameterValue
   * @param masterSystem
   * @param collectionName
   */
  createNewCollection(
    parameterValue: string,
    masterSystem: string,
    collectionName: string,
  ): Observable<DocumentCollectionDto> {
    const documentCollection: InsertDocumentCollectionDto = DocumentService.prepareCollection(
      parameterValue,
      masterSystem,
      collectionName,
    );
    return this.httpClient.post(
      this.bfcConfigurationService.configuration.apiUrl + "/DocumentCollections",
      documentCollection,
    );
  }

  /**
   * because of having multiple entries with same name, check customerNumber too.
   * @param customerName
   * @param customerNumber
   */
  static getUniqueCustomerName(customerName: string, customerNumber: string): string {
    return !!customerNumber ? customerName + " (" + customerNumber + ")" : customerName;
  }

  static prepareCollection(
    paramValue: string,
    masterSystem: string,
    collectionName: string,
  ): InsertDocumentCollectionDto {
    const parameterType = ParameterType.getParameterTypeByValue(paramValue, masterSystem);

    if (
      parameterType.parameterPrefix === ParameterType.MSD_ACCOUNT_NUMBER.parameterPrefix ||
      parameterType.parameterPrefix === ParameterType.MSD_CUSTOMER_NUMBER.parameterPrefix ||
      parameterType.parameterPrefix === ParameterType.MSD_NET_ACCOUNT_NUMBER.parameterPrefix ||
      parameterType.parameterPrefix === ParameterType.MSD_NET_CUSTOMER_NUMBER.parameterPrefix
    ) {
      return {
        customerId: parameterType.docHubAttribute + ParameterType.getValueWithoutPrefix(paramValue, masterSystem),
        name: collectionName,
        system: masterSystem,
        metaData: {
          customerNumber: ParameterType.getValueWithoutPrefix(paramValue, masterSystem),
        },
      };
    }
    return {
      customerId: parameterType.docHubAttribute + ParameterType.getValueWithoutPrefix(paramValue, masterSystem),
      name: collectionName,
      system: masterSystem,
    };
  }

  private static mapToCustomerDocument(collection: DocumentCollectionDto, document: DocumentDto): CustomerDocument {
    return {
      customerName: collection.name,
      customerNumber: collection.metaData?.customerNumber,
      document: document,
    };
  }
}
