import { IDocumentsAdminAppConfiguration } from "../configuration";

export const IS_IE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const GENERAL_ENV: IDocumentsAdminAppConfiguration = {
  googleTagManagerContainerId: "GTM-N4VBJZH",
  b2bExportConfig: {
    collectionName: "OP_B2B_EXPORT",
  },
  dsvConfig: {
    collectionName: "DSV_MARKET_REPORTS",
  },
  dateFormat: "dd.MM.yyyy",
  dateTimeFormat: "dd.MM.yyyy HH:mm:ss",
  inputDateFormat: "yyyy-MM-dd",
};
