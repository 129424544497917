import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {

  readonly title: string;

  readonly description: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { title: string, description: string },
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    this.title = data.title;
    this.description = data.description;
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
