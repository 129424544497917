import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Injectable()
export class InitializerProvider {
  constructor(
    private bfcTranslateService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  initialize() {
    return new Promise<void>((resolve) => {
      this.bfcGoogleAnalyticsService.initGoogleTagManager(
        this.bfcConfigurationService.configuration.googleTagManagerContainerId,
      );

      document.title = this.bfcTranslateService.translate("SERVICE_NAME");

      const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
      if (globalNavElements.length === 1) {
        globalNavElements[0].setAttribute("enable-breadcrumbs", "false");
        globalNavElements[0].setAttribute("enable-account-menu", "false");
      } else {
        throw new Error("too many or no global navigation element found");
      }

      this.matIconRegistry.addSvgIcon(
        "plus",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Plus.svg"),
      );

      resolve();
    });
  }
}
