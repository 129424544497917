import { BfcConfigurationService } from "@bfl/components/configuration";
import { DocumentServiceCodeEnum } from "../model/document-service-code.enum";
import { PermissionDto } from "../../generated/doc-hub/model/permissionDto";
import { IdentityType } from "../../generated/doc-hub/model/identityType";
import { PermissionAttributeEnum } from "../model/permission-attribute.enum";
import { DocumentPatchOperation } from "../model/document-patch-operation";
import { Injectable } from "@angular/core";

@Injectable()
export class DocumentPermissionService {
  constructor(private bfcConfigurationService: BfcConfigurationService) {}

  getDocumentPermissions(serviceCode: DocumentServiceCodeEnum): Array<PermissionDto> {
    let permissions: PermissionDto[] = [];

    if (serviceCode === DocumentServiceCodeEnum.ROLE_DOCUMENTS) {
      permissions = [
        {
          identity: "Company_AllUsers", // <- Fixer string, immer gleich 
          // => DocHub sucht automatisch die richtige AD Gruppe von dem User und setzt sie (=> AAD_xyc_AllUsers)
          identityType: IdentityType.DocHub, // <- Ist immer gleich
          read: true,
          write: true,
          changePermission: true,
          inheritable: false,
        },
        {
          identity: serviceCode, // <- ist die entsprechende Rolle (ROLE_DOCUMENTS oder ROLE_NET_USAGE_DOCUMENTS)
          identityType: IdentityType.OpServiceCode, // <- Ist immer gleich
          read: true,
          write: true,
          changePermission: true,
          inheritable: false,
        },
      ];
    } else if (serviceCode === DocumentServiceCodeEnum.ROLE_NET_USAGE_DOCUMENTS) {
      permissions = [
        {
          identity: this.bfcConfigurationService.configuration.netUsageAdGroup, 
          // ^ ist die AD Gruppe für die CRM Netze Benutzer (=> auf P: L_APP_D365_N)
          identityType: IdentityType.AdGroup, // <- Ist immer gleich
          read: true,
          write: true,
          changePermission: true,
          inheritable: false,
        },
        {
          identity: serviceCode, 
          // ^ ist die entsprechende Rolle (ROLE_DOCUMENTS oder ROLE_NET_USAGE_DOCUMENTS)
          identityType: IdentityType.OpServiceCode, // <- Ist immer gleich
          read: true,
          write: true,
          changePermission: true,
          inheritable: false,
        },
      ];
    }
    return permissions;
  }

  attributeValue(permission: PermissionDto, permissionAttribute: PermissionAttributeEnum): boolean | string {
    let value;

    switch (permissionAttribute) {
      case PermissionAttributeEnum.READ: {
        value = permission?.read !== null ? permission.read : "?";
        break;
      }
      case PermissionAttributeEnum.WRITE: {
        value = permission?.write !== null ? permission.write : "?";
        break;
      }
      case PermissionAttributeEnum.CHANGE_PERMISSION: {
        value = permission?.changePermission !== null ? permission.changePermission : "?";
        break;
      }
      case PermissionAttributeEnum.INHERITABLE: {
        value = permission?.inheritable !== null ? permission.inheritable : "?";
        break;
      }
    }

    return value;
  }

  getCommonPermissions(allPermissions: PermissionDto[][]): PermissionDto[] {
    return allPermissions.reduce((previous: PermissionDto[], next: PermissionDto[]): PermissionDto[] => {
      const commonPermissions: PermissionDto[] = [];

      next.forEach((nextPermission) => {
        previous.forEach((prevPermission) => {
          if (
            prevPermission.identityType === nextPermission.identityType &&
            prevPermission.identity === nextPermission.identity
          ) {
            // set to null if there is a conflict
            if (prevPermission.write !== nextPermission.write) {
              prevPermission.write = null;
            }

            if (prevPermission.read !== nextPermission.read) {
              prevPermission.read = null;
            }

            if (prevPermission.changePermission !== nextPermission.changePermission) {
              prevPermission.changePermission = null;
            }

            if (prevPermission.inheritable !== nextPermission.inheritable) {
              prevPermission.inheritable = null;
            }

            if (commonPermissions.indexOf(prevPermission) === -1) {
              commonPermissions.push(prevPermission);
            }
          }
        });
      });

      return commonPermissions;
    });
  }

  permissionsEqual(permission1: PermissionDto, permission2: PermissionDto): boolean {
    return (
      permission1.identity === permission2.identity &&
      permission1.identityType === permission2.identityType &&
      permission1.read === permission2.read &&
      permission1.write === permission2.write &&
      permission1.changePermission === permission2.changePermission &&
      permission1.inheritable === permission2.inheritable &&
      permission1.isInherited === permission2.isInherited
    );
  }

  getPatchOperations(
    documentPermissions: PermissionDto[],
    permissionsToRemove: PermissionDto[],
    permissionsToAdd: PermissionDto[],
  ): DocumentPatchOperation[] {
    const patchOperations: DocumentPatchOperation[] = [];
    const indicesToRemove: number[] = [];

    documentPermissions.forEach((permission: PermissionDto, index: number) => {
      permissionsToRemove.forEach((permissionToRemove: PermissionDto) => {
        if (this.permissionsEqual(permissionToRemove, permission)) {
          if (!indicesToRemove.includes(index)) {
            indicesToRemove.push(index);
          }
        }
      });
    });

    indicesToRemove
      .reverse()
      .forEach((indexToRemove: number) => patchOperations.push({ op: "remove", path: `/${indexToRemove}` }));

    if (permissionsToAdd && permissionsToAdd.length > 0) {
      permissionsToAdd.forEach((permissionToAdd: PermissionDto) => {
        const existingPermission: PermissionDto = documentPermissions.find((permission: PermissionDto) =>
          this.permissionsEqual(permissionToAdd, permission),
        );
        if (!existingPermission) {
          patchOperations.push({ op: "add", path: "/-", value: permissionToAdd });
        }
      });
    }

    return patchOperations;
  }
}
