import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BfcTranslationModule } from "@bfl/components/translation";
import { CommonModule } from "@angular/common";
import { DocumentCollectionEditPermissionsDialogComponent } from "./document-collection-edit-permissions-dialog.component";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcButtonModule } from "@bfl/components/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcSelectModule } from "@bfl/components/select";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcTableModule } from "@bfl/components/table";
import { BfcTooltipModule } from "@bfl/components/tooltip";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [
    BfcTranslationModule,
    CommonModule,
    BfcDialogModule,
    BfcButtonModule,
    FlexLayoutModule,
    BfcFormModule,
    BfcFormFieldModule,
    BfcInputModule,
    BfcSelectModule,
    BfcLoadingModule,
    BfcTableModule,
    BfcTooltipModule,
    MatIconModule,
  ],
  declarations: [
    DocumentCollectionEditPermissionsDialogComponent,
  ],
  entryComponents: [
    DocumentCollectionEditPermissionsDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentCollectionEditPermissionsDialogModule {
}
