import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentCollectionDto } from "../../../generated/doc-hub/model/documentCollectionDto";
import { DocumentService } from "../../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { throwError } from "rxjs";
import { MatInput } from "@angular/material/input";
import { MetaData } from "../../../core/model/meta-data";

@Component({
  selector: "app-document-collection-edit-data-dialog",
  templateUrl: "./document-collection-edit-data-dialog.component.html",
  styleUrls: ["./document-collection-edit-data-dialog.component.scss"],
})
export class DocumentCollectionEditDataDialogComponent {
  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  public readonly selectedCollection: DocumentCollectionDto;

  private readonly callbackOnSuccess: () => void;

  formGroup: FormGroup;

  showTableLoading = false;

  errorOnSubmit = false;

  metaDataFormGroup: FormGroup;

  metaDataEntries: [string, string][];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedCollection: DocumentCollectionDto;
      callBackOnSuccess: () => void;
    },
    private dialogRef: MatDialogRef<DocumentCollectionEditDataDialogComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
  ) {
    this.selectedCollection = data.selectedCollection;
    this.callbackOnSuccess = data.callBackOnSuccess;

    this.metaDataEntries = Object.entries(this.selectedCollection.metaData);

    const customerNumber = this.metaDataEntries?.find((entry) => entry[0] === "customerNumber");

    if (!customerNumber) {
      this.metaDataEntries.push(["customerNumber", ""]);
    }

    this.populateMetaDataFormGroup();

    this.formGroup = formBuilder.group({
      name: [this.selectedCollection?.name, [Validators.required]],
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;

      this.selectedCollection.name = this.formGroup.get("name").value as string;

      const metaData: MetaData = {};

      Object.keys(this.metaDataFormGroup.controls).forEach((controlName) => {
        metaData[controlName] = this.metaDataFormGroup.get(controlName).value;
      });

      this.selectedCollection.metaData = metaData;

      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    if (!!this.selectedCollection) {
      return this.documentService.updateDocumentCollection(this.selectedCollection);
    } else {
      return throwError("Not implemented!");
    }
  }

  private populateMetaDataFormGroup(): void {
    if (this.selectedCollection && this.metaDataEntries) {
      const metaData: MetaData = {};

      this.metaDataEntries.forEach((entry) => {
        metaData[entry[0]] = entry[1];
      });

      this.metaDataFormGroup = this.formBuilder.group(metaData);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
