export class ParameterType {
  static readonly MSD_ACCOUNT_NUMBER = new ParameterType("msdynamics", "msdynamics-accountnumber:", "A");

  static readonly MSD_CUSTOMER_NUMBER = new ParameterType("msdynamics", "msdynamics-customernumber:", "C");

  static readonly MSD_NET_ACCOUNT_NUMBER = new ParameterType("msdynamicsn", "msdynamicsn-accountnumber:", "A");

  static readonly MSD_NET_CUSTOMER_NUMBER = new ParameterType("msdynamicsn", "msdynamicsn-customernumber:", "C");

  static readonly MSD_NET_GPNUMBER = new ParameterType("msdynamicsn", "msdynamicsn-gpnumber:", "");

  static readonly MSD_CUSTOMER_ID = new ParameterType("msdynamics", "cid:", "");

  private constructor(
    readonly mastersystem: string,
    readonly docHubAttribute: string,
    readonly parameterPrefix: string,
  ) {}

  static getParameterTypeByValue(parameter: string, masterSystem: string): ParameterType {
    if (
      parameter?.startsWith(this.MSD_ACCOUNT_NUMBER.parameterPrefix) &&
      this.MSD_ACCOUNT_NUMBER.mastersystem == masterSystem
    ) {
      return this.MSD_ACCOUNT_NUMBER;
    } else if (
      parameter?.startsWith(this.MSD_NET_ACCOUNT_NUMBER.parameterPrefix) &&
      this.MSD_NET_ACCOUNT_NUMBER.mastersystem == masterSystem
    ) {
      return this.MSD_NET_ACCOUNT_NUMBER;
    } else if (
      parameter?.startsWith(this.MSD_CUSTOMER_NUMBER.parameterPrefix) &&
      this.MSD_CUSTOMER_NUMBER.mastersystem == masterSystem
    ) {
      return this.MSD_CUSTOMER_NUMBER;
    } else if (
      parameter?.startsWith(this.MSD_NET_CUSTOMER_NUMBER.parameterPrefix) &&
      this.MSD_NET_CUSTOMER_NUMBER.mastersystem == masterSystem
    ) {
      return this.MSD_NET_CUSTOMER_NUMBER;
    } else if (this.MSD_NET_GPNUMBER.mastersystem == masterSystem) {
      return this.MSD_NET_GPNUMBER;
    }
    return this.MSD_CUSTOMER_ID;
  }

  static getValueWithoutPrefix(parameter: string, masterSystem: string): string {
    const paramType = ParameterType.getParameterTypeByValue(parameter, masterSystem);
    return parameter ? parameter.substr(paramType.parameterPrefix.length, parameter.length) : parameter;
  }

  static isParameterARealCustomerId(parameter: string, masterSystem: string): boolean {
    const paramType = this.getParameterTypeByValue(parameter, masterSystem);
    return paramType?.parameterPrefix?.length === 0 && !isNaN(+parameter);
  }

  static convertParameter(originalParameter: number, paramType: ParameterType): string {
    return paramType.parameterPrefix + originalParameter;
  }
}
