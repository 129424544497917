import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BfcTranslationService } from "@bfl/components/translation";
import { DocumentCollectionDto } from "../../../generated/doc-hub/model/documentCollectionDto";
import { DocumentService } from "../../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { DocumentType } from "../../../generated/doc-hub/model/documentType";
import { DocumentDto } from "../../../generated/doc-hub/model/documentDto";
import { throwError } from "rxjs";
import { MatInput } from "@angular/material/input";
import { SharepointNetUsageParameters } from "../../../core/model/sharepoint-net-usage-parameters";
import { DatePipe } from "@angular/common";
import { DocumentServiceCodeEnum } from "../../../core/model/document-service-code.enum";
import { DocumentPermissionService } from "../../../core/services/document-permission.service";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Component({
  selector: "app-net-usage-contracts-upload-dialog",
  templateUrl: "./net-usage-contracts-upload-dialog.component.html",
  styleUrls: ["./net-usage-contracts-upload-dialog.component.scss"],
})
export class NetUsageContractsUploadDialogComponent {
  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  private readonly selectedCollection: DocumentCollectionDto;

  private readonly callbackOnSuccess: () => void;

  private readonly unterzeichnetStatus: string = "425210001"; // Dummy status "Unterzeichnet" --> Active, because of Dochub validation

  private readonly dateFormat: string = this.bfcConfigurationService.configuration.inputDateFormat;

  formGroup: FormGroup;

  selectedFileName = "";

  showTableLoading = false;

  errorOnSubmit = false;

  readonly documentForEditing: DocumentDto;

  readonly sharepointNetUsageParameters: SharepointNetUsageParameters;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedCollection: DocumentCollectionDto;
      callBackOnSuccess: () => void;
      document?: DocumentDto;
      sharepointNetUsageParameters?: SharepointNetUsageParameters;
    },
    private dialogRef: MatDialogRef<NetUsageContractsUploadDialogComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private translationService: BfcTranslationService,
    private datePipe: DatePipe,
    private documentPermissionService: DocumentPermissionService,
    private bfcConfigurationService: BfcConfigurationService,
  ) {
    this.selectedCollection = data.selectedCollection;
    this.callbackOnSuccess = data.callBackOnSuccess;
    this.documentForEditing = data.document;
    this.sharepointNetUsageParameters = data.sharepointNetUsageParameters;

    this.formGroup = formBuilder.group({
      contractNumber: [
        this.documentForEditing ? this.documentForEditing.metaData.contractNumber : null,
        [Validators.required],
      ],
      name: [this.documentForEditing ? this.documentForEditing.name : null, [Validators.required]],
      connectionObject: [
        this.documentForEditing ? this.documentForEditing.metaData.connectionObject : null,
        [Validators.required],
      ],
      startDate: [
        this.documentForEditing
          ? this.datePipe.transform(this.documentForEditing.metaData.startDate, this.dateFormat)
          : null,
        [Validators.required],
      ],
    });
    this.selectedFileName = this.translationService.translate("CONTRACTS.UPLOAD.DIALOG.NO_FILE_SELECTED");
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;
      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    if (!!this.documentForEditing) {
      return this.documentService.updateDocument(this.documentForEditing, [
        { op: "add", path: "/name", value: this.formGroup.value.name },
        { op: "add", path: "/metaData/contractNumber", value: this.formGroup.value.contractNumber },
        { op: "add", path: "/metaData/startDate", value: this.formGroup.value.startDate },
        { op: "add", path: "/metaData/connectionObject", value: this.formGroup.value.connectionObject },
      ]);
    } else if (!!this.sharepointNetUsageParameters) {
      this.selectedFileName = this.sharepointNetUsageParameters.documentPath;
      if (this.selectedFileName.indexOf("/") > 0) {
        this.selectedFileName = this.selectedFileName.substring(
          this.selectedFileName.lastIndexOf("/") + 1,
          this.selectedFileName.length,
        );
      } else {
        return throwError("sharePointParameters.documentPath is not an URL!");
      }
      return this.documentService.uploadSharePointDocument(
        {
          siteId: this.sharepointNetUsageParameters.siteId,
          listId: this.sharepointNetUsageParameters.listId,
          documentId: this.sharepointNetUsageParameters.documentId,
          documentPath: this.sharepointNetUsageParameters.documentPath,
          filename: this.selectedFileName,
          name: this.formGroup.value.name,
          type: DocumentType.Contract,
          permissions: this.documentPermissionService.getDocumentPermissions(
            DocumentServiceCodeEnum.ROLE_NET_USAGE_DOCUMENTS,
          ),
          metaData: {
            contractNumber: this.formGroup.value.contractNumber,
            startDate: this.formGroup.value.startDate,
            connectionObject: this.formGroup.value.connectionObject,
            endDate: this.datePipe.transform(new Date(9999, 11, 31), this.dateFormat),
            status: this.unterzeichnetStatus,
          },
        },
        this.selectedCollection,
      );
    } else {
      return throwError("Not implemented!");
    }
  }

  getCustomer(): string {
    return this.documentService.getUniqueCustomerNameFromCollection(this.selectedCollection);
  }

  close() {
    this.dialogRef.close();
  }

  isDocumentAvailable(): boolean {
    return !!this.documentForEditing || !!this.sharepointNetUsageParameters;
  }

  getTitleSuffix(): string {
    return !!this.documentForEditing ? "EDIT" : "UPLOAD";
  }
}
