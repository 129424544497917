/**
 * DocHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IdentityType = 'AdUser' | 'AdGroup' | 'AdApp' | 'OpUser' | 'OpCId' | 'OpServiceCode' | 'DocHub' | 'Collection';

export const IdentityType = {
    AdUser: 'AdUser' as IdentityType,
    AdGroup: 'AdGroup' as IdentityType,
    AdApp: 'AdApp' as IdentityType,
    OpUser: 'OpUser' as IdentityType,
    OpCid: 'OpCId' as IdentityType,
    OpServiceCode: 'OpServiceCode' as IdentityType,
    DocHub: 'DocHub' as IdentityType,
    Collection: 'Collection' as IdentityType
};

