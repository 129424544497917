<bfc-loading-overlay [loading]="showTableLoading">
  <mat-dialog-content>
    <h1>{{ "MARKET_REPORTS.UPLOAD.DIALOG.TITLE" | bfcTranslate }}</h1>

    <form [formGroup]="formGroup">
      <div class="bfc-form-row">
        <div class="csv-file-upload-button" (click)="fileUpload.click()">
          <div class="file-upload-button-content">
            <span matSuffix class="upload-icon-container">
              <bfe-icon name="bfc-icon-arrowup"></bfe-icon>
            </span>
            <span class="upload-action-title">{{ "MARKET_REPORTS.UPLOAD.DIALOG.SELECT_FILE" | bfcTranslate }}</span>
          </div>
        </div>

        <span>{{ selectedFileName }}</span>

        <div class="bfc-form-row bfc-margin-top">
          <mat-form-field>
            <mat-label>{{ "MARKET_REPORTS.LIST.COLUMNS.NAME" | bfcTranslate }}</mat-label>
            <input matInput type="text" formControlName="name" name="weeklyReportName" required />
          </mat-form-field>
        </div>

        <div class="bfc-form-row">
          <mat-form-field>
            <mat-label>{{ "MARKET_REPORTS.DSV_MARKET_REPORT_TYPE" | bfcTranslate }}</mat-label>
            <mat-select formControlName="marketReport">
              <mat-option *ngFor="let marketReport of marketReports" [value]="marketReport">
                {{ "MARKET_REPORTS.DSV_MARKET_REPORT_TYPES." + marketReport.type | bfcTranslate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <input
          type="file"
          formControlName="fileSelector"
          class="fileUpload"
          #fileUpload
          (input)="onUpload($event)"
          accept="*/*"
          required
        />

        <div *ngIf="errorOnSubmit">
          <mat-error>{{ "MARKET_REPORTS.UPLOAD.UPLOAD_ERROR" | bfcTranslate }}</mat-error>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ "MARKET_REPORTS.UPLOAD.DIALOG.CANCEL" | bfcTranslate }}
    </button>
    <button mat-button color="primary" class="bfc-button-hero" (click)="submit()" [disabled]="!formGroup.valid">
      {{ "MARKET_REPORTS.UPLOAD.DIALOG.SUBMIT" | bfcTranslate }}
    </button>
  </mat-dialog-actions>
</bfc-loading-overlay>
