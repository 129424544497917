/**
 * DocHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentType = 'Document' | 'Contract' | 'Invoice' | 'MdmReport' | 'Template' | 'DsvMarketReport' | 'ConnectionObjectDocument' | 'MdlOrder';

export const DocumentType = {
    Document: 'Document' as DocumentType,
    Contract: 'Contract' as DocumentType,
    Invoice: 'Invoice' as DocumentType,
    MdmReport: 'MdmReport' as DocumentType,
    Template: 'Template' as DocumentType,
    DsvMarketReport: 'DsvMarketReport' as DocumentType,
    ConnectionObjectDocument: 'ConnectionObjectDocument' as DocumentType,
    MdlOrder: 'MdlOrder' as DocumentType
};

