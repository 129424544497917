import { Client } from "@microsoft/microsoft-graph-client";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private msalService: MsalService,
    private configService: BfcConfigurationService,
  ) {}

  private getAuthenticationResult(): Observable<AuthenticationResult | void> {
    const account: AccountInfo = this.selectCurrentOrFirstAccount();

    // if account exists and token is not expired
    if (!!account && moment.unix(account.idTokenClaims.exp).isAfter(moment())) {
      const requestObj = {
        scopes: ["user.read"],
        account: account,
      };
      return this.msalService.acquireTokenSilent(requestObj);
    } else {
      const requestObj = {
        scopes: ["user.read"],
      };
      return this.msalService.acquireTokenRedirect(requestObj);
    }
  }

  private selectCurrentOrFirstAccount(): AccountInfo {
    if (this.msalService.instance.getActiveAccount()) {
      return this.msalService.instance.getActiveAccount();
    }
    return this.msalService.instance.getAllAccounts()[0];
  }

  public async isUserAuthorized(): Promise<boolean> {
    let authorized = false;

    const graphClient = Client.init({
      authProvider: (done) => {
        this.getAuthenticationResult().subscribe((result: AuthenticationResult) => {
          if (result) {
            const token = result.accessToken;
            if (token) {
              done(null, token);
            } else {
              done("Could not get an access token", null);
            }
          }
        });
      },
    });

    // Get the user from Graph (GET /me)
    const graphUser = await graphClient.api("/me").get();
    const graphUserGroups: any = await graphClient.api(`/users/${graphUser.id}/memberOf`).get();

    const adminGroupId: string = this.configService.configuration.adminAdGroupId;

    const foundGroup = graphUserGroups?.value?.find((graphUserGroup) => graphUserGroup.id === adminGroupId);

    if (!!foundGroup) {
      authorized = true;
    }

    return authorized;
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserAuthorized().then((t) => {
      if (!t) {
        this.router.navigate(["overview"]);
      }
      return t;
    });
  }
}
