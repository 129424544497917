import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { BfcNotificationModule } from "@bfl/components/notification";

import { initializerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { DialogService } from "./services/dialog.service";
import { DocumentService } from "./services/document.service";
import { DocumentPermissionService } from "./services/document-permission.service";
import { ErrorService } from "./services/error.service";
import { OpServicebundleService } from "./services/op-servicbundle.service";

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    BfcNotificationModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    DialogService,
    DocumentService,
    DocumentPermissionService,
    ErrorService,
    OpServicebundleService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
