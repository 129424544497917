import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BfcTranslationService } from "@bfl/components/translation";
import { ErrorService } from "../../core/services/error.service";
import { DocumentCollectionDto } from "../../generated/doc-hub/model/documentCollectionDto";
import { DocumentService } from "../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { DocumentType } from "../../generated/doc-hub/model/documentType";
import { DocumentDto } from "../../generated/doc-hub/model/documentDto";
import { throwError } from "rxjs";
import { SharepointParameters } from "../../core/model/sharepoint-parameters";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { DocumentServiceCodeEnum } from "../../core/model/document-service-code.enum";
import { DocumentPermissionService } from "../../core/services/document-permission.service";
import { DatePipe } from "@angular/common";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class ContractsUploadDialogComponent implements OnInit {
  @ViewChild("fileUpload")
  private uploadElement: ElementRef;

  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  @ViewChild(MatSelect)
  private statusSelection: MatSelect;

  private fileContent = undefined;

  private selectedCollection: DocumentCollectionDto;

  private callbackOnSuccess: () => void;

  private readonly dateFormat = this.bfcConfigurationService.configuration.inputDateFormat;

  readonly statusMapping: Map<string, string> = new Map([
    ["ACTIVE", "425210001"], // 425210001 bedeutet "unterzeichnet"
    ["FULFILLED", "100001"],
  ]);

  formGroup: FormGroup;

  selectedFileName = "";

  showTableLoading = false;

  errorOnSubmit = false;

  documentForEditing: DocumentDto;

  sharePointParameters: SharepointParameters;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedCollection: DocumentCollectionDto;
      callBackOnSuccess: () => void;
      document?: DocumentDto;
      sharepointParameters?: SharepointParameters;
    },
    private dialogRef: MatDialogRef<ContractsUploadDialogComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private translationService: BfcTranslationService,
    private errorService: ErrorService,
    private documentPermissionService: DocumentPermissionService,
    private datePipe: DatePipe,
    private bfcConfigurationService: BfcConfigurationService,
  ) {}

  ngOnInit(): void {
    this.selectedCollection = this.data.selectedCollection;
    this.callbackOnSuccess = this.data.callBackOnSuccess;
    this.documentForEditing = this.data.document;
    this.sharePointParameters = this.data.sharepointParameters;

    this.formGroup = this.formBuilder.group({
      fileSelector: [null, this.documentForEditing || this.sharePointParameters ? [] : [Validators.required]],
      name: [this.documentForEditing ? this.documentForEditing.name : null, [Validators.required]],
      quoteNumber: [this.documentForEditing ? this.documentForEditing.metaData.quoteNumber : null, []],
      contractNumber: [
        this.documentForEditing ? this.documentForEditing.metaData.contractNumber : null,
        [Validators.required],
      ],
      startDate: [
        this.documentForEditing
          ? this.datePipe.transform(this.documentForEditing.metaData.startDate, this.dateFormat)
          : null,
        [Validators.required],
      ],
      endDate: [
        this.documentForEditing
          ? this.datePipe.transform(this.documentForEditing.metaData.endDate, this.dateFormat)
          : null,
        [Validators.required],
      ],
      status: [this.documentForEditing ? this.documentForEditing.metaData.status : null, [Validators.required]],
    });
    this.selectedFileName = this.translationService.translate("CONTRACTS.UPLOAD.DIALOG.NO_FILE_SELECTED");
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;
      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      } else if (!!this.statusSelection && this.statusSelection.ngControl.invalid) {
        this.statusSelection.focus();
      }
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    if (!!this.fileContent) {
      return this.documentService.uploadDocument(
        {
          file: this.fileContent,
          filename: this.selectedFileName,
          name: this.formGroup.value.name,
          type: DocumentType.Contract,

          permissions: this.documentPermissionService.getDocumentPermissions(DocumentServiceCodeEnum.ROLE_DOCUMENTS),
          metaData: {
            quoteNumber: this.formGroup.value.quoteNumber,
            contractNumber: this.formGroup.value.contractNumber,
            startDate: this.formGroup.value.startDate,
            endDate: this.formGroup.value.endDate,
            status: this.formGroup.value.status,
          },
        },
        this.selectedCollection,
      );
    } else if (!!this.documentForEditing) {
      return this.documentService.updateDocument(this.documentForEditing, [
        { op: "add", path: "/name", value: this.formGroup.value.name },
        { op: "add", path: "/metaData/quoteNumber", value: this.formGroup.value.quoteNumber },
        { op: "add", path: "/metaData/contractNumber", value: this.formGroup.value.contractNumber },
        { op: "add", path: "/metaData/startDate", value: this.formGroup.value.startDate },
        { op: "add", path: "/metaData/endDate", value: this.formGroup.value.endDate },
        { op: "add", path: "/metaData/status", value: this.formGroup.value.status },
      ]);
    } else if (!!this.sharePointParameters) {
      this.selectedFileName = this.sharePointParameters.documentPath;
      if (this.selectedFileName.indexOf("/") > 0) {
        this.selectedFileName = this.selectedFileName.substring(
          this.selectedFileName.lastIndexOf("/") + 1,
          this.selectedFileName.length,
        );
      } else {
        return throwError("sharePointParameters.documentPath is not an URL!");
      }
      return this.documentService.uploadSharePointDocument(
        {
          siteId: this.sharePointParameters.siteId,
          listId: this.sharePointParameters.listId,
          documentId: this.sharePointParameters.documentId,
          documentPath: this.sharePointParameters.documentPath,
          filename: this.selectedFileName,
          name: this.formGroup.value.name,
          type: DocumentType.Contract,
          permissions: this.documentPermissionService.getDocumentPermissions(DocumentServiceCodeEnum.ROLE_DOCUMENTS),
          metaData: {
            quoteNumber: this.formGroup.value.quoteNumber,
            contractNumber: this.formGroup.value.contractNumber,
            startDate: this.formGroup.value.startDate,
            endDate: this.formGroup.value.endDate,
            status: this.formGroup.value.status,
          },
        },
        this.selectedCollection,
      );
    } else {
      return throwError("Not implemented!");
    }
  }

  getCustomer(): string {
    return this.documentService.getUniqueCustomerNameFromCollection(this.selectedCollection);
  }

  close() {
    this.dialogRef.close();
  }

  onUpload(event: Event) {
    this.fileContent = undefined;
    const reader = new FileReader();
    try {
      const fileUploadInput = event.srcElement as HTMLInputElement;
      if (fileUploadInput.files.length > 0) {
        reader.readAsDataURL(fileUploadInput.files[0]); // base64 encoded
        reader.onload = (evt) => {
          let title: string = (this.uploadElement as any).nativeElement.value;
          if (title.indexOf("\\") > 0) {
            title = title.substring(title.lastIndexOf("\\") + 1, title.length);
          }
          this.selectedFileName = title;
          const content: string = (evt.target as any).result;
          if (!!content && content.indexOf(",") > -1) {
            this.fileContent = content.split(",")[1];
          } else {
            this.fileContent = content;
          }
        };
        reader.onerror = () => {
          this.errorService.handleError(null, this.translationService.translate("CONTRACTS.UPLOAD.FILE_READING_ERROR"));
        };
      } else {
        this.selectedFileName = this.translationService.translate("CONTRACTS.UPLOAD.DIALOG.NO_FILE_SELECTED");
      }
    } catch (e) {
      this.errorService.handleError(e, this.translationService.translate("CONTRACTS.UPLOAD.FILE_READING_ERROR"));
    }
  }

  isDocumentAvailable(): boolean {
    return !!this.fileContent || !!this.documentForEditing || !!this.sharePointParameters;
  }

  getTitleSuffix(): string {
    return !!this.documentForEditing ? "EDIT" : "UPLOAD";
  }
}
