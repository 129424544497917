<bfc-loading-overlay [loading]="showTableLoading">
    <mat-dialog-content>
        <h1> {{ "NET_USAGE.CONNECTION_OBJECTS.DIALOG.TITLE." + getTitleSuffix() | bfcTranslate }} </h1>

        <form [formGroup]="formGroup">
            <div class="bfc-form-row bfc-padding-top-1 customer-name">
                {{ "NET_USAGE.OWNER" | bfcTranslate }}: <b>{{ getCustomer() }}</b>
            </div>

            <div *ngIf="!!sharepointNetUsageParameters" class="bfc-form-row">
                <div style="word-wrap: break-word">
                    {{sharepointNetUsageParameters.documentPath}}
                </div>
                <div *ngIf="errorOnSubmit">
                    <mat-error>{{ "NET_USAGE.CONNECTION_OBJECTS.UPLOAD.UPLOAD_ERROR" | bfcTranslate }}</mat-error>
                </div>
            </div>

            <div *ngIf="isDocumentAvailable()">

                <div *ngIf="!!documentForEditing && errorOnSubmit" class="bfc-padding-bottom">
                    <mat-error>{{ "NET_USAGE.CONNECTION_OBJECTS.LIST.EDIT_ERROR" | bfcTranslate }}</mat-error>
                </div>

                <div class="bfc-form-row">
                    <mat-form-field>
                    <mat-label>{{'NET_USAGE.CONNECTION_OBJECTS.LIST.COLUMNS.NAME' | bfcTranslate }}</mat-label>
                        <input matInput type="text" formControlName="name" name="name" required>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button color="primary" (click)="close()">
            {{ "CONTRACTS.UPLOAD.DIALOG.CANCEL" | bfcTranslate }}
        </button>
        <button mat-button color="primary" class="bfc-button-hero" [disabled]="!isDocumentAvailable()"
                (click)="submit()">
            {{ "CONTRACTS.UPLOAD.DIALOG.SUBMIT" | bfcTranslate }}
        </button>
    </mat-dialog-actions>
</bfc-loading-overlay>
