<h2 matDialogTitle>{{ title }}</h2>

<mat-dialog-content>
  <p>{{ description }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" class="bfc-button-hero" (click)="cancel()">
    {{ "DIALOG.BUTTON.CANCEL" | bfcTranslate }}
  </button>
  <button mat-button color="primary" class="bfc-button-secondary" (click)="submit()">
    {{ "DIALOG.BUTTON.SUBMIT" | bfcTranslate }}
  </button>
</mat-dialog-actions>
