import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentCollectionDto } from "../../../generated/doc-hub/model/documentCollectionDto";
import { DocumentService } from "../../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { DocumentType } from "../../../generated/doc-hub/model/documentType";
import { DocumentDto } from "../../../generated/doc-hub/model/documentDto";
import { throwError } from "rxjs";
import { MatInput } from "@angular/material/input";
import { SharepointNetUsageParameters } from "../../../core/model/sharepoint-net-usage-parameters";
import { DocumentServiceCodeEnum } from "../../../core/model/document-service-code.enum";
import { DocumentPermissionService } from "../../../core/services/document-permission.service";

@Component({
  selector: "app-net-usage-connection-objects-contracts-upload-dialog",
  templateUrl: "./net-usage-connection-objects-upload-dialog.component.html",
  styleUrls: ["./net-usage-connection-objects-upload-dialog.component.scss"],
})
export class NetUsageConnectionObjectsUploadDialogComponent {
  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  private readonly selectedCollection: DocumentCollectionDto;

  private readonly callbackOnSuccess: () => void;

  formGroup: FormGroup;

  selectedFileName = "";

  showTableLoading = false;

  errorOnSubmit = false;

  readonly documentForEditing: DocumentDto;

  readonly sharepointNetUsageParameters: SharepointNetUsageParameters;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedCollection: DocumentCollectionDto;
      callBackOnSuccess: () => void;
      document?: DocumentDto;
      sharepointNetUsageParameters?: SharepointNetUsageParameters;
    },
    private dialogRef: MatDialogRef<NetUsageConnectionObjectsUploadDialogComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private documentPermissionService: DocumentPermissionService,
  ) {
    this.selectedCollection = data.selectedCollection;
    this.callbackOnSuccess = data.callBackOnSuccess;
    this.documentForEditing = data.document;
    this.sharepointNetUsageParameters = data.sharepointNetUsageParameters;

    if (!!this.sharepointNetUsageParameters) {
      this.extractFileName();
    }

    this.formGroup = formBuilder.group({
      name: [this.documentForEditing ? this.documentForEditing.name : this.selectedFileName, [Validators.required]],
    });
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;
      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  private extractFileName() {
    this.selectedFileName = this.sharepointNetUsageParameters.documentPath;
    if (this.selectedFileName?.indexOf("/") > 0) {
      this.selectedFileName = this.selectedFileName.substring(
        this.selectedFileName.lastIndexOf("/") + 1,
        this.selectedFileName.length,
      );
    } else {
      return throwError("sharePointParameters.documentPath is not an URL!");
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    if (!!this.documentForEditing) {
      return this.documentService.updateDocument(this.documentForEditing, [
        { op: "add", path: "/name", value: this.formGroup.value.name },
      ]);
    } else if (!!this.sharepointNetUsageParameters) {
      return this.documentService.uploadSharePointDocument(
        {
          siteId: this.sharepointNetUsageParameters.siteId,
          listId: this.sharepointNetUsageParameters.listId,
          documentId: this.sharepointNetUsageParameters.documentId,
          documentPath: this.sharepointNetUsageParameters.documentPath,
          filename: this.selectedFileName,
          name: this.formGroup.value.name,
          type: DocumentType.ConnectionObjectDocument,
          permissions: this.documentPermissionService.getDocumentPermissions(
            DocumentServiceCodeEnum.ROLE_NET_USAGE_DOCUMENTS,
          ),
        },
        this.selectedCollection,
      );
    } else {
      return throwError("Not implemented!");
    }
  }

  getCustomer(): string {
    return this.documentService.getUniqueCustomerNameFromCollection(this.selectedCollection);
  }

  close() {
    this.dialogRef.close();
  }

  isDocumentAvailable(): boolean {
    return !!this.documentForEditing || !!this.sharepointNetUsageParameters;
  }

  getTitleSuffix(): string {
    return !!this.documentForEditing ? "EDIT" : "UPLOAD";
  }
}
