import { NgModule } from "@angular/core";
import { BfcTranslationModule } from "@bfl/components/translation";
import { CommonModule } from "@angular/common";
import { AddOrEditPermissionDialogComponent } from "./add-or-edit-permission-dialog.component";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcButtonModule } from "@bfl/components/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcSelectModule } from "@bfl/components/select";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcCheckboxModule } from "@bfl/components/checkbox";

@NgModule({
  imports: [
    BfcTranslationModule,
    CommonModule,
    BfcDialogModule,
    BfcButtonModule,
    FlexLayoutModule,
    BfcFormModule,
    BfcFormFieldModule,
    BfcInputModule,
    BfcSelectModule,
    BfcLoadingModule,
    BfcCheckboxModule,
  ],
  declarations: [
    AddOrEditPermissionDialogComponent,
  ],
  entryComponents: [
    AddOrEditPermissionDialogComponent,
  ],
})
export class AddOrEditPermissionDialogModule {
}
