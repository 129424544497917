import { Component, ElementRef, Inject, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BfcTranslationService } from "@bfl/components/translation";
import { ErrorService } from "../../../core/services/error.service";
import { DocumentCollectionDto } from "../../../generated/doc-hub/model/documentCollectionDto";
import { DocumentService } from "../../../core/services/document.service";
import { finalize } from "rxjs/operators";
import { DocumentType } from "../../../generated/doc-hub/model/documentType";
import { DocumentDto } from "../../../generated/doc-hub/model/documentDto";
import { MatInput } from "@angular/material/input";
import { DsvMarketReportType } from "../dsv-market-report-type.enum";
import { IdentityType } from "src/app/generated/doc-hub/model/identityType";

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class MarketReportsUploadDialogComponent {
  @ViewChild("fileUpload")
  private uploadElement: ElementRef;

  @ViewChildren(MatInput)
  private textInputs: QueryList<MatInput>;

  private fileContent = undefined;

  private readonly selectedCollection: DocumentCollectionDto;

  private readonly callbackOnSuccess: () => void;

  formGroup: FormGroup;

  selectedFileName = "";

  showTableLoading = false;

  errorOnSubmit = false;

  marketReports: { type: DsvMarketReportType; serviceCode: string }[] = [
    { type: DsvMarketReportType.KMU_REPORT, serviceCode: "ROLE_DSV_MONTHLY_MARKET_REPORT" },
    { type: DsvMarketReportType.MONTHLY_RADAR, serviceCode: "ROLE_DSV_MONTHLY_RADAR_REPORT" },
    { type: DsvMarketReportType.WEEKLY_REPORT, serviceCode: "ROLE_DSV_WEEKLY_MARKET_REPORT" },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedCollection: DocumentCollectionDto;
      callBackOnSuccess: () => void;
      document?: DocumentDto;
    },
    formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarketReportsUploadDialogComponent>,
    private documentService: DocumentService,
    private translationService: BfcTranslationService,
    private errorService: ErrorService,
  ) {
    this.selectedCollection = data.selectedCollection;
    this.callbackOnSuccess = data.callBackOnSuccess;

    this.formGroup = formBuilder.group({
      fileSelector: [null, Validators.required],
      name: [null, Validators.required],
      marketReport: [null, Validators.required],
    });
    this.selectedFileName = this.translationService.translate("MARKET_REPORTS.UPLOAD.DIALOG.NO_FILE_SELECTED");
  }

  submit() {
    if (this.formGroup.valid) {
      this.errorOnSubmit = false;
      this.showTableLoading = true;
      this.createHttpRequest()
        .pipe(finalize(() => (this.showTableLoading = false)))
        .subscribe(
          () => {
            this.dialogRef.close();
            this.callbackOnSuccess();
          },
          () => {
            this.errorOnSubmit = true;
          },
        );
    } else {
      this.validateAllFields(this.formGroup);
      const invalidInput = this.textInputs?.find((input) => input.ngControl.invalid);
      if (!!invalidInput) {
        invalidInput.focus();
      }
    }
  }

  private validateAllFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private createHttpRequest() {
    return this.documentService.uploadDocument(
      {
        file: this.fileContent,
        filename: this.selectedFileName,
        name: this.formGroup.value.name,
        type: DocumentType.Document,
        permissions: [
          {
            identity: this.formGroup.value.marketReport.serviceCode,
            identityType: IdentityType.OpServiceCode,
            read: true,
            write: false,
            changePermission: false,
            inheritable: false,
          },
        ],
        metaData: {
          dsvMarketReportType: this.formGroup.value.marketReport.type,
        },
      },
      this.selectedCollection,
    );
  }

  getCustomer(): string {
    return this.documentService.getUniqueCustomerNameFromCollection(this.selectedCollection);
  }

  close() {
    this.dialogRef.close();
  }

  onUpload(event: Event) {
    this.fileContent = undefined;
    const reader = new FileReader();
    try {
      const fileUploadInput = event.srcElement as HTMLInputElement;
      if (fileUploadInput.files.length > 0) {
        reader.readAsDataURL(fileUploadInput.files[0]); // base64 encoded
        reader.onload = (evt) => {
          let title: string = (this.uploadElement as any).nativeElement.value;
          if (title.indexOf("\\") > 0) {
            title = title.substring(title.lastIndexOf("\\") + 1, title.length);
          }
          this.selectedFileName = title;
          const content: string = (evt.target as any).result;
          if (!!content && content.indexOf(",") > -1) {
            this.fileContent = content.split(",")[1];
          } else {
            this.fileContent = content;
          }
        };
        reader.onerror = () => {
          this.errorService.handleError(
            null,
            this.translationService.translate("MARKET_REPORTS.UPLOAD.FILE_READING_ERROR"),
          );
        };
      } else {
        this.selectedFileName = this.translationService.translate("MARKET_REPORTS.UPLOAD.DIALOG.NO_FILE_SELECTED");
      }
    } catch (e) {
      this.errorService.handleError(e, this.translationService.translate("MARKET_REPORTS.UPLOAD.FILE_READING_ERROR"));
    }
  }
}
