import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BfcTranslationModule } from "@bfl/components/translation";
import { CommonModule } from "@angular/common";
import { NetUsageContractsUploadDialogComponent } from "./net-usage-contracts-upload-dialog.component";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcButtonModule } from "@bfl/components/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcSelectModule } from "@bfl/components/select";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcDatepickerModule } from "@bfl/components/datepicker";

@NgModule({
  imports: [
    BfcTranslationModule,
    CommonModule,
    BfcDialogModule,
    BfcButtonModule,
    FlexLayoutModule,
    BfcFormModule,
    BfcFormFieldModule,
    BfcInputModule,
    BfcSelectModule,
    BfcLoadingModule,
    BfcDatepickerModule,
  ],
  declarations: [NetUsageContractsUploadDialogComponent],
  entryComponents: [NetUsageContractsUploadDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NetUsageContractsUploadDialogModule {}
