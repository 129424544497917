import { NgModule } from "@angular/core";
import { BfcTranslationModule } from "@bfl/components/translation";
import { CommonModule } from "@angular/common";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcButtonModule } from "@bfl/components/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@NgModule({
  imports: [
    BfcTranslationModule,
    CommonModule,
    BfcDialogModule,
    BfcButtonModule,
    FlexLayoutModule,
  ],
  declarations: [
    ConfirmationDialogComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
  ],
})
export class ConfirmationDialogModule {
}
