import { IBfcConfiguration } from "@bfl/components/configuration";

import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { P_ENV } from "./environments/p";
import { Q_ENV } from "./environments/q";
import { T_ENV } from "./environments/t";
import { Configuration } from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface IDocumentsAdminAppConfiguration {
  msalConfig?: Configuration;
  msalAngularInterceptorConfig?: MsalInterceptorConfiguration;
  msalAngularGuardConfig?: MsalGuardConfiguration;
  googleTagManagerContainerId?: string;
  apiUrl?: string;
  opDocumentAdminApiUrl?: string;
  csvMappingFilename?: string;
  b2bExportConfig?: {
    collectionName: string
  };
  dsvConfig?: {
    collectionName: string
  };
  dateFormat?: string;
  dateTimeFormat?: string;
  inputDateFormat?: string;
  adminAdGroupId?: string;
  netUsageAdGroup?: string;
}
